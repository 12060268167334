import React, { useState, useEffect, useRef } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import Markdown from "react-markdown";
import {
  Dialog,
  Group,
  Text,
  Loader,
  Center,
  Image,
  Card,
  Container,
  Textarea,
  Button,
  Stack,
  ScrollArea,
} from "@mantine/core";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { IconCheck, IconArrowLeft } from "@tabler/icons-react";
import {client} from "../api/axiosClient"






const StreamComponent = ({ url }) => {
  const [fullText, setFullText] = useState("");
  const [visibleChars, setVisibleChars] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const currentNotification = useStore((state) => state.currentNotification);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      setError(null);
      setFullText("");
      setVisibleChars(0);

      try {
        const response = await fetch(url, { signal });

        if (!response.body) {
          throw new Error("ReadableStream not yet supported in this browser.");
        }

        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();

        let accumulatedText = "";
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          if (isMounted) {
            accumulatedText += value;
            setFullText((prev) => prev + value);
          }
        }
        setLoading(false);
      } catch (error) {
        if (error.name !== "AbortError" && isMounted) {
          setError(error);
          setLoading(false);
        }
      }
    };

    fetchData();

    // Afficher la notification de chargement
    notifications.update({
      id: currentNotification,
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Group gap="xs">
          <Image
            fit="contain"
            src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
            alt="iAvicenne Logo"
            width={25}
            height={25}
            style={{ margin: "0", display: "block" }}
          />
          <Text fw={700}>Reflexion Terminée</Text>
          <IconCheck
            style={{ width: "1.1rem", color: "#94ADA7", height: "1.1rem" }}
          />
        </Group>
      ),

      opacity: 0.7,
      autoClose: 1000,
      withCloseButton: true,


    });

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [url]);

  useEffect(() => {
    if (visibleChars < fullText.length) {
      const timer = setTimeout(() => {
        setVisibleChars((prev) => Math.min(prev + 1, fullText.length));
      }, 3);

      return () => clearTimeout(timer);
    }
  }, [fullText, visibleChars]);

  const visibleText = fullText.slice(0, visibleChars);

  return (
    <ScrollArea h={"65vh"} offsetScrollbars scrollHideDelay={100}>
      <Card
        className="AudioChatCard"
        withCloseButton={true}
        size="xl"
        radius="12px"
        shadow="lg"
        withBorder={true}
      >
        <Text size="sm" mb="xs" fw={500} ta="left">
          {loading && (
            <Center>
              <Loader className="ChatLoader" color="#94ADA7" type="dots" />
            </Center>
          )}
          {error && <p>Error: {error.message}</p>}
          <Markdown>{visibleText}</Markdown>
        </Text>
      </Card>
    </ScrollArea>
  );
};

export function RecordQuestion() {
  const [streamUrl, setStreamUrl] = useState("");
  const loading = useStore((state) => state.loading);
  const setLoading = useStore((state) => state.setLoading);
  const setCurrentNotification = useStore(
    (state) => state.setCurrentNotification
  );
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);


  const uploadAudioBlob = async (blob) => {
    // Déterminer l'extension du fichier en fonction du type MIME du blob
    const mimeType = blob.type;
    let fileExtension = "";

    if (mimeType === "audio/webm") {
        fileExtension = "webm";
    } else if (mimeType === "audio/wav") {
        fileExtension = "wav";
    } else if (mimeType === "audio/mp4") {
        fileExtension = "mp4";
    } else {
        console.warn("Type MIME non supporté. Utilisation de 'webm' par défaut.");
        fileExtension = "webm";
    }

    const fileName = `audio.${fileExtension}`;
    
    const formData = new FormData();
    formData.append("file", blob, fileName);
    formData.append("practitioner_id", currentSessionInfo.practitioner.toString());

    try {
      const response = await client.post("api/audio-file/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        console.log("Fichier audio téléchargé avec succès");
        const transcription = await client.get(
          `api/audio-file/${response.data.id}/transcribe/`
        );
        if (transcription.status === 200) {
          const transcriptMedical = transcription.data.transcription;
          setStreamUrl(
            `https://www.iavicenne.fr/api/chat/?message=${transcriptMedical}`
          );
          const deleteAudio = await client.delete(
            `api/audio-file/${response.data.id}/`
          );
          if (deleteAudio.status === 200) {
            console.log("fichier Audio supprimé (RGPD compliance)");
          }
        }
      } else {
        console.error(
          "Erreur lors du téléchargement du fichier audio:",
          response
        );
      }
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier audio:", error);
    }
  };

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    // document.body.appendChild(audio);
    // console.log(audio);
    const notificationId = notifications.show({
      withBorder: true,
      loading: false,
      radius: "lg",
      color: "#94ADA7",
      title: (
        <Group gap="xs">
          <Image
            fit="contain"
            src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
            alt="iAvicenne Logo"
            width={25}
            height={25}
            style={{ margin: "0", display: "block" }}
          />
          <Text fw={700}>Je réfléchis</Text>
          <Loader className="ChatLoader" color="#94ADA7" type="dots" />
        </Group>
      ),

      opacity: 0.7,
      autoClose: false,
      withCloseButton: true,


    });
    setCurrentNotification(notificationId);
    uploadAudioBlob(blob);
  };

  return (
    <>
      <Stack>
        <Container className="ChatQuestionRecorder">
          {streamUrl && <StreamComponent url={streamUrl} />}

          <Container className="ChatRecorderElements">
            <Group gap="xs" align="center">
              <AudioRecorder
                className="chatAudio"
                onRecordingComplete={addAudioElement}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                onNotAllowedOrFound={(err) => console.table(err)}
                downloadOnSavePress={false}
                downloadFileExtension="webm"
                mediaRecorderOptions={{
                  audioBitsPerSecond: 128000,
                }}
                showVisualizer={true}
              />
              <IconArrowLeft size={14} />
              <Text size="xs">Cliquez-ici pour poser votre Question</Text>
            </Group>
          </Container>
          <Container className="ChatRecorderTextArea">
            <Textarea
              className="ChatTextarea"
              variant="default"
              radius="12px"
              shadow="lg"
              label=""
              placeholder="Cliquer sur l'icone Microphone, posez votre question puis cliquez sur l'icone Enregistrer"
              autosize
              minRows={5}
              disabled
            />
          </Container>
        </Container>
        {loading && (
          <Center>
            <Loader className="ChatLoader" color="#94ADA7" type="dots" />
          </Center>
        )}
      </Stack>
    </>
  );
}
