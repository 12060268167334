import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Card,
  Text,
  Stack,
  Alert,
  Group,
  Indicator,
  Grid,
  Divider,
  ScrollArea,
  Fieldset,
  Title
} from "@mantine/core";
import { useStore } from "../Store";
import { format, parseISO, set } from "date-fns";
import { fr } from "date-fns/locale";
import { client } from "../api/axiosClient";
import {
  IconAlertTriangleFilled,
  IconDropletFilled,
  IconFileTypePdf,
  IconMailFilled,
  IconMailOpenedFilled,
} from "@tabler/icons-react";
import { TypographyStylesProvider } from "@mantine/core";
import DOMPurify from "dompurify";

const formatDate = (dateString) => {
  if (!dateString) return "Date non définie";

  const now = new Date();
  const dateToFormat = parseISO(dateString);

  if (now.toDateString() === dateToFormat.toDateString()) return "Aujourd'hui";

  return format(dateToFormat, "dd/MM/yyyy", { locale: fr });
};

const formatDate2 = (dateString) => {
  if (!dateString) return "Date non définie";

  const now = new Date();
  const dateToFormat = parseISO(dateString);

  if (now.toDateString() === dateToFormat.toDateString()) return "Aujourd'hui";

  return format(dateToFormat, "dd MMMM yyyy 'à' HH'h'mm", { locale: fr });
};

export function Mailbox() {
  // const email = "899700578851@mail-tests-editeur.espacedeconfiance.mssante.fr";
  const email = "othman.harit@medecin.mssante.fr";
  const { mailboxData, setMailboxData, practitioner } = useStore();
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [disabledEmails, setDisabledEmails] = useState({});
  const [error, setError] = useState(null);
  const [border, setBorder] = useState("3px solid #fff");

  // console.log({ selectedEmail: selectedEmail });

  const fetchMails = useCallback(async () => {
    //   // Deuxième fetch (api/mssante/fetchBAL)
    //   try {
    //     const response2 = await client.get(
    //       // `api/mssante/fetchBAL/?email=${email}`
    //       // `api/mssante/`
    //     );
    //     console.log({ response2: response2 });

    //     if (response2.status === 200) {
    //       // setMailboxData(prevData => ({
    //       //   ...prevData,
    //       //   email_list: [...prevData.email_list, ...(response2.data.email_list || [])]
    //       // }));
    //       setError(null);
    //     }
    //   } catch (error2) {
    //     console.error("Error fetching BAL:", error2);
    //     // Gérer l'erreur spécifique au deuxième fetch si nécessaire
    //   }
    try {
      // Premier fetch (api/mssante)
      const response1 = await client.get(
        `api/mssante/?practitioner=67&&limit=50`
      );
      console.log({ response1: response1 });

      if (response1.status === 200) {
        // setMailboxData(prevData => ({
        //   ...prevData,
        //   email_list: response1.data
        // }));
        setMailboxData({ email_list: response1.data.results });
        setSelectedEmail(response1.data.results[0]);
      }

      setError(null);
      console.log({ mailboxData: mailboxData });
    } catch (error1) {
      console.error("Error fetching mails from database:", error1);
      setError(
        error1.response?.data ||
          error1.message ||
          "An error occurred while fetching mails from database"
      );
    }
  }, [client, email, setMailboxData, setError]);

  useEffect(() => {
    fetchMails();
  }, [fetchMails]);

  const HandleEmailClick = (emailItem) => {
    setSelectedEmail(emailItem);

    // Gérer l'état désactivé pour chaque email individuel
    setDisabledEmails((prevState) => ({
      ...prevState,
      [emailItem.id]: true, // Désactiver l'email cliqué
    }));

    try {
      PatchMailData(emailItem);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'email :", error);
    }
  };

  const PatchMailData = async (emailItem) => {
    const newData = {
      ...emailItem,
      is_read: true,
    };
    try {
      const response = await client.put(
        `api/mssante/${emailItem.id}/`,
        newData
      );
      if (response.status !== 200) {
        throw new Error("La mise à jour a échoué.");
      }
    } catch (error) {
      throw new Error(
        "Erreur lors de la mise à jour des données : " + error.message
      );
    }
  };

  return (
    <Grid>
      <Grid.Col span={4} className="MailsGridCol">
        <Divider my="xs" label="Liste des Messages" labelPosition="center" />
        <ScrollArea
          h="88vh"
          w="100%"
          offsetScrollbars
          scrollHideDelay={100}
          scrollbarSize={8}
          style={{ translate: "25px" }}
        >
          <Stack gap="xs">
            {mailboxData.email_list
              ?.sort((a, b) => new Date(b.date) - new Date(a.date))
              ?.map((emailItem) => (
                <Indicator
                  key={emailItem.hash}
                  position="middle-end"
                  disabled={disabledEmails[emailItem.id] || emailItem.is_read} // Désactiver si dans l'état
                  size={18}
                  withBorder
                  style={{ marginRight: 15 }}
                >
                  <Card
                    // className="mailButton"
                    shadow="lg"
                    radius="12px"
                    onClick={() => HandleEmailClick(emailItem)}
                    style={{
                      cursor: "pointer",
                      border:
                        selectedEmail?.id === emailItem.id
                          ? "3px solid #94ADA7"
                          : "3px solid #fff",
                    }}
                  >
                    <Group justify="space-between">
                      <Group gap="xs">
                        {emailItem.sender.includes("DGS-URGENT") ? (
                          selectedEmail?.id === emailItem.id ? (
                            <IconAlertTriangleFilled
                              color="#94ADA7"
                              size="18px"
                            />
                          ) : (
                            <IconAlertTriangleFilled
                              color="#94ADA7"
                              size="18px"
                            />
                          )
                        ) : emailItem.subject.includes("HNET.1/MSG/") ? (
                          selectedEmail?.id === emailItem.id ? (
                            <IconDropletFilled color="#f03e3eab" size="25px" />
                          ) : (
                            <IconDropletFilled color="#f03e3eab" size="25px" />
                          )
                        ) : selectedEmail?.id === emailItem.id ? (
                          <IconMailOpenedFilled color="#e4b400ad" size="25px" />
                        ) : (
                          <IconMailFilled color="#e4b400ad" size="25px" />
                        )}
                        <Text
                          size="sm"
                          ta="left"
                          fw={700}
                          truncate="end"
                          w="15vw"
                        >
                          {emailItem.sender}
                        </Text>
                      </Group>
                      <Group gap="xs">
                        {emailItem.attachments.length &&
                          (selectedEmail?.id === emailItem.id ? (
                            <IconFileTypePdf color="#c92a2a" size="25px" />
                          ) : (
                            <IconFileTypePdf color="#c92a2a" size="25px" />
                          ))}
                        <Text size="md" ta="right">
                          {formatDate(emailItem.date)}
                        </Text>
                      </Group>
                    </Group>
                    <Text size="sm" ta="left" lineClamp={1}>
                      {emailItem.subject}
                    </Text>
                    {/* <Text size="sm" ta="left" c="gray" truncate="end" w="20vw">
                      {emailItem.content}
                    </Text> */}
                  </Card>
                </Indicator>
              ))}
          </Stack>
        </ScrollArea>
      </Grid.Col>
      <Grid.Col span={8} className="MailsGridCol">
        <Divider my="xs" label="Contenu du Message" labelPosition="center" />
        {error && <Alert color="red">{error}</Alert>}
        <Card shadow="lg" radius="12px" padding="lg" style={{ height: "100%" }}>
          {selectedEmail && (
            <Stack gap="xs">
              <Grid>
                <Grid.Col span={8}>
                  <Group>
                    <Text size="sm" ta="left" fw={700}>
                      De
                    </Text>
                    <Text size="sm" ta="left" fw={700} c="#94ADA7">
                      {selectedEmail.sender}
                    </Text>
                    <Text size="sm" ta="left" fw={700}>
                      le {formatDate2(selectedEmail.date)}
                    </Text>
                  </Group>
                  <Group gap="xs">
                    <Text size="sm" ta="left" fw={700}>
                      Sujet:
                    </Text>
                    <Text size="sm" ta="left">
                      {selectedEmail.subject}
                    </Text>
                  </Group>

                  <Group gap="xs">
                    <Text size="sm" ta="left" fw={700}>
                      Pièces Jointes:
                    </Text>
                    {selectedEmail.attachments.length > 0 &&
                      selectedEmail.attachments?.map?.((document, index) => (
                        <a
                          key={index}
                          href={`https://www.iavicenne.fr/${document.path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {document.filename}
                        </a>
                      ))}
                  </Group>
                </Grid.Col>
                <Grid.Col span={4}>
                  {selectedEmail.attachments.length > 0 &&
                    selectedEmail.attachments.map((document, index) => (
                      <Fieldset
                        legend="Identité du Patient"
                        radius="12px"
                        ta="left"
                      >
                        <Stack gap="xs">
                          {document.metadata?.ClinicalDocument?.recordTarget
                            ?.patientRole?.id?.root === "1.2.250.1.213.1.4.8" ||
                            ("1.2.250.1.213.1.4.10" && (
                              <Group>
                                <Text size="sm" ta="left" fw={700} c="#94ADA7">
                                  INS:
                                </Text>
                                <Text size="sm" ta="left" fw={700} c="#94ADA7">
                                  {
                                    document.metadata?.ClinicalDocument
                                      ?.recordTarget?.patientRole?.id[0]
                                      ?.extension
                                  }
                                </Text>
                              </Group>
                            ))}
                          {document.metadata?.ClinicalDocument ? (
                            <Text size="sm" ta="left" fw={700}>
                              {
                                document.metadata?.ClinicalDocument
                                  ?.recordTarget?.patientRole?.patient.name
                                  .family.text
                              }{" "}
                              {
                                document.metadata?.ClinicalDocument
                                  ?.recordTarget?.patientRole?.patient.name
                                  .given.text
                              }{" "}
                              {
                                document.metadata?.ClinicalDocument
                                  ?.recordTarget?.patientRole?.patient.birthTime
                                  .value
                              }{" "}
                              {
                                document.metadata?.ClinicalDocument
                                  ?.recordTarget?.patientRole?.patient
                                  .administrativeGenderCode.code
                              }
                            </Text>
                          ) : (
                            <Text size="sm" ta="left" fw={700}>
                              {
                                document.metadata?.nom.recordTarget?.patientRole
                                  ?.patient.name.family.text
                              }{" "}
                              {
                                document.metadata?.ClinicalDocument
                                  ?.recordTarget?.patientRole?.patient.name
                                  .given.text
                              }{" "}
                              {
                                document.metadata?.ClinicalDocument
                                  ?.recordTarget?.patientRole?.patient.birthTime
                                  .value
                              }{" "}
                              {
                                document.metadata?.ClinicalDocument
                                  ?.recordTarget?.patientRole?.patient
                                  .administrativeGenderCode.code
                              }
                            </Text>
                          )}
                        </Stack>
                      </Fieldset>
                    ))}
                </Grid.Col>
              </Grid>
              <Divider my="xs" labelPosition="center" />
              <ScrollArea
                h="77vh"
                w="100%"
                offsetScrollbars
                scrollHideDelay={100}
                scrollbarSize={8}
              >
               
               { selectedEmail.subject.includes("HNET.1/MSG/") && selectedEmail.attachments.length > 0 && (
                           
                            selectedEmail.attachments.map((document, index) => (
                document.metadata?.ClinicalDocument?.component?.structuredBody?.component.map((section,index) =>(
                  <Stack>
                  <Title ta="left" order={3}>
                  {section.section?.component?.section?.code?.displayName}
                  </Title>
                  <Group>
                  <Text size="sm" ta="left">
                  {section.section?.component?.section?.entry?.act?.entryRelationship?.observation?.code?.originalText?.reference?.value}
                  </Text>
                  <Text size="sm" ta="left">
                  {section.section?.component?.section?.entry?.act?.entryRelationship?.observation?.value?.value}
                  {section.section?.component?.section?.entry?.act?.entryRelationship?.observation?.value?.unit}
                  </Text>
                  <Text size="sm" ta="left">
                  {section.section?.component?.section?.entry?.act?.entryRelationship?.observation?.interpretationCode?.displayName}
                  </Text>
                  <Text size="sm" ta="left">
                  {section.section?.component?.section?.entry?.act?.entryRelationship?.observation?.low?.value}
                  </Text>
                  <Text size="sm" ta="left">
                  {section.section?.component?.section?.entry?.act?.entryRelationship?.observation?.high?.value}
                  </Text>
                  </Group>
                  </Stack>
                    
                )
             
              )
            ))
               )}
                {selectedEmail.content.HTMLContent.length > 0 ? (
                  <Text size="sm" ta="left">
                    <TypographyStylesProvider>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            selectedEmail.content.HTMLContent
                          ),
                        }}
                      />
                    </TypographyStylesProvider>
                  </Text>
                ) : (
                  selectedEmail.content.TextContent.length > 0 && (
                    <Text size="sm" ta="left">
                      {DOMPurify.sanitize(selectedEmail.content.TextContent)}
                    </Text>
                  )
                )}

                {selectedEmail.attachments?.map?.((document, index) => {
                  const path = document.path;
                  if (!path || typeof path !== "string") {
                    return null;
                  }

                  return (
                    <iframe
                      key={index}
                      src={`https://www.iavicenne.fr/${path}`}
                      width="100%"
                      style={{ borderRadius: "16px", height: "100vh" }}
                      // sandbox="allow-same-origin allow-scripts"
                      title={`attachment-${index}`}
                    />
                  );
                })}
              </ScrollArea>
            </Stack>
          )}
        </Card>
      </Grid.Col>
    </Grid>
  );
}
