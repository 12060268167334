import React, { useState, useEffect } from "react";
import { Text, Button, Tooltip, Group } from "@mantine/core";
import { IconCertificate, IconFile, IconPlus } from "@tabler/icons-react";

import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { formatDate, set } from "date-fns";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

import {client} from "../api/axiosClient"

export const CreateCertificat = ({ type, tooltipText }) => {
  const { setNewEditorState, selectedPatientInfo, currentUser,ordonnanceLibreDate, practitioner } = useStore();
  const { setActive, setActiveTab } = useStore();
  const [loading, setLoading] = useState(false);


  const FormatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM yyyy", { locale: fr });
  };

  function calculateAge(birthDate) {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    return age;
  }

  const Nom = selectedPatientInfo.NomNaissance;
  const Prenoms = selectedPatientInfo.ListePrenom;
  const Naissance = selectedPatientInfo.birthDate;
  const Age = calculateAge(Naissance);
  let patient;
  if (Age > 12) {
    patient =
      selectedPatientInfo.gender === "male"
        ? `Monsieur ${Prenoms} ${Nom}`
        : `Madame ${Prenoms} ${Nom}`;
  } else {
    patient = `l'Enfant ${Prenoms} ${Nom}`;
  }
  console.log(practitioner)

  const certificatText = `Je soussigné(e), Dr ${practitioner.given_name} ${practitioner.family_name}, certifie avoir examiné, le ${FormatDate(ordonnanceLibreDate)},  ${patient}, né(e) le ${FormatDate(Naissance)}.
L'examen clinique ne révèle, ce jour, aucune contre-indication à la pratique du sport.


                      Certificat fait le ${FormatDate(ordonnanceLibreDate)} et remis en main propre`;

  const createContent = async () => {
    setLoading(true);
    try {
      const editorState = {
        root: {
          type: "root",
          format: "",
          indent: 0,
          version: 1,
          children: [
            {
              type: "paragraph",
              format: "",
              indent: 0,
              version: 1,
              children: [
                {
                  mode: "normal",
                  text: certificatText,
                  type: "text",
                  style: "",
                  detail: 0,
                  format: 0,
                  version: 1,
                },
              ],
              direction: "ltr",
              textFormat: 0,
            },
          ],
        },
      };

      const NewEditoState = {
        trigger: true,
        root: editorState,
      };
      setNewEditorState(NewEditoState);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
      label={<Text size="xs">{tooltipText}</Text>}
      color="#94ADA7"
      withArrow
      multiline
      position="top"
      radius="12px"
      arrowOffset={25}
      arrowSize={8}
      transitionProps={{ transition: "pop", duration: 500 }}
      openDelay={1000}
    >
      <Button
        className="certificatButton"
        variant='subtle'
        color="#94ADA7"
        radius="12px"
        size="compact-xs"
        onClick={() => {
          createContent();
          setActive("autres");
          setActiveTab("autres");
        }}
        loading={loading}
        disabled={loading}
      >
        <Group gap="xs">
        <IconCertificate size={16} />
        {type}
        </Group>
      </Button>
     
    </Tooltip>
  );
};
