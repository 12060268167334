// hooks/useAntecedents.js

import { useStore } from '../Store';
import { client } from '../api/axiosClient';

export const DeleteAntecedent = async (type, id,selectedPatientInfo,setSelectedPatientInfo) => {
    // const {

    //     selectedPatientInfo,
    //     setSelectedPatientInfo,

    //   } = useStore();
    try {
      let updatedAntecedents, updatedFields, antecedentKey;

      switch (type) {
        case "medical":
          updatedAntecedents = selectedPatientInfo.antecedentsMedicaux.filter(
            (antecedent) => {
              antecedentKey = Object.keys(antecedent)[0];
              return antecedent[antecedentKey].id !== id;
            }
          );
          updatedFields = { antecedentsMedicaux: updatedAntecedents };

          break;

        case "chirurgical":
          updatedAntecedents =
            selectedPatientInfo.antecedentsChirurgicaux.filter((antecedent) => {
              antecedentKey = Object.keys(antecedent)[0];
              return antecedent[antecedentKey].id !== id;
            });
          updatedFields = { antecedentsChirurgicaux: updatedAntecedents };
          break;

        case "familial":
          updatedAntecedents = selectedPatientInfo.antecedentsFamiliaux.filter(
            (antecedent) => {
              antecedentKey = Object.keys(antecedent)[0];
              return antecedent[antecedentKey].id !== id;
            }
          );
          updatedFields = { antecedentsFamiliaux: updatedAntecedents };
          break;

        case "allergies":
          updatedAntecedents = selectedPatientInfo.allergies.filter(
            (antecedent) => {
              antecedentKey = Object.keys(antecedent)[0];
              return antecedent[antecedentKey].id !== id;
            }
          );
          updatedFields = { allergies: updatedAntecedents };
          break;

        // case "traitements":
        //   updatedMedicationStatements = selectedPatientInfo.MedicationStatement.filter(
        //     (medicament) => medicament.id !== id
        //   );
        //   updatedFields = { MedicationStatement: updatedMedicationStatements };
        //   setSelectedPatientInfo((prevInfo) => ({
        //     ...prevInfo,
        //     MedicationStatement: updatedMedicationStatements,
        //   }));
        //   break;

        default:
          throw new Error(`Unknown antecedent type: ${type}`);
      }

      // Requête PATCH pour mettre à jour le modèle du patient
      const response = await client.patch(
        `api/patients/${selectedPatientInfo.id}/`,
        updatedFields
      );

      // Vérification de la réponse du serveur
      if (response.status >= 200 && response.status < 300) {
        console.log("Antécédent supprimé avec succès");
        setSelectedPatientInfo(response.data);
      } else {
        console.log("La suppression de l'antécédent n'a pas pu être effectuée");
      }
    } catch (error) {
      console.log(error);
    }
  };

export const DeletetttFond = async (medoc_id,selectedPatientInfo,setSelectedPatientInfo,) => {

    try {
      const prevData = selectedPatientInfo.MedicationStatement.filter(
        (item) => item.medoc_id !== medoc_id
      );
      const updatedFields = { MedicationStatement: prevData };

      // Requête PATCH pour mettre à jour le modèle du patient
      const response = await client.patch(
        `api/patients/${selectedPatientInfo.id}/`,
        updatedFields
      );
      // Vérification de la réponse du serveur
      if (response.status >= 200 && response.status < 300) {
        console.log("ttt de fond supprimé avec succès");
        setSelectedPatientInfo(response.data);
      } else {
        console.log("La suppression de l'antécédent n'a pas pu être effectuée");
      }
    } catch (error) {
      console.log(error);
    }
  };
