import React, { useEffect, useState, useCallback } from "react";
import { Select, Loader, Group, Text, Badge } from "@mantine/core";
import { IconBandage, IconSearch } from "@tabler/icons-react";
import { useStore } from "../Store";
import {client} from "../api/axiosClient"

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
async function getDispositifMedicals(query = "") {
  const response = await client.get(
    `api/liste-dispositifs-medicaux/search/?dm=${query}`
  );
  if (response.status !== 200) throw new Error("Network response was not ok");
  return response.data;
}

function SearchDispositifMedical() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const dispositifMedicalData = useStore(
    (state) => state.dispositifMedicalData
  );
  const setDispositifMedicalData = useStore(
    (state) => state.setDispositifMedicalData
  );

  const debouncedGetDispositifMedicals = useCallback(
    debounce(async (query) => {
      try {
        const DispositifMedicaux = await getDispositifMedicals(query);
        console.log(DispositifMedicaux);
        const formattedData = DispositifMedicaux.results.map(
          (DispositifMedical) => ({
            value: String(DispositifMedical.id),
            label: `${DispositifMedical.denominationCommerciale}`,
            dispositifMedical: DispositifMedical,
          })
        );
        setData(formattedData);
      } catch (error) {
        console.error("Failed to fetch DispositifMedicals:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (query) {
      debouncedGetDispositifMedicals(query);
    } else {
      setData([]);
      setQuery("");
    }
  }, [query, debouncedGetDispositifMedicals]);

  // Choisir une dispositifMedical et l'enregitrer dasn l'ordonnance en cours
  const handleDispositifMedicalClick = async (value) => {
    const DispositifMedicalData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      categoryContent: String("DispositifMedical").trim(),
      ServiceId: parseInt(value.value, 10),
      ServiceContent: [{ label: value.dispositifMedical, tttAld: false }],
    };
    console.log(DispositifMedicalData);

    try {
      const isDispositifMedicalIdPresent = dispositifMedicalData.some(
        (item) => item.serviceId === parseInt(value.value, 10)
      );
      if (isDispositifMedicalIdPresent) {
        // Si l'entrée existe, mettre à jour
        // const existingId = response_1.data[0].id;
        console.log("cette dispositifMedical à déja été prescrite");
      } else {
        // Si l'entrée n'existe pas, créer une nouvelle entrée
        const updatedDispositifMedical = [
          ...dispositifMedicalData,
          DispositifMedicalData,
        ];
        setDispositifMedicalData(updatedDispositifMedical);
        const response = await client.post(
          "api/service-request/",
          DispositifMedicalData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 201) {
          const newDispositifMedicalData = {
            ...DispositifMedicalData,
            id: response.data.id, // Utiliser l'ID retourné par l'API
          };
          setDispositifMedicalData([
            ...dispositifMedicalData,
            newDispositifMedicalData,
          ]);
          console.log("dispositifMedical mise à jour");
        } else {
          console.error("Failed to save new dispositifMedical:", response);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Select
      searchable
      clearable
      placeholder="Rechercher un Dispositif Médical"
      data={data}
      allowDeselect={false}
      maxDropdownHeight={400}
      searchValue={query}
      value={selectedValue}
      onSearchChange={setQuery}
      onChange={(value, DispositifMedical) => {
        if (DispositifMedical) {
          handleDispositifMedicalClick(DispositifMedical);
          setSelectedValue(null);
          setQuery("");
          setData([]);
        }
      }}
      comboboxProps={{
        transitionProps: { transition: "pop", duration: 200 },
        shadow: "lg",
        radius: "lg",
      }}
      style={{ width: "100%", marginBottom: 20 }}
      leftSection={<IconSearch size="1rem" style={{ display: "block" }} />}
      rightSection={loading && <Loader size="sm" />}
      renderOption={(props) => {
        // console.log("Props in renderOption:", props);
        return (
          <Group {...props} gap="xs" align="center">
            <Text size="xs" fw={700}>
              {" "}
              <IconBandage
                size="1rem"
                color="#94ADA7"
                style={{ verticalAlign: "middle", marginRight: "3" }}
              />{" "}
              {props.option.dispositifMedical.denominationCommerciale}
            </Text>
            <Text size="xs" fs="italic">
              {props.option.dispositifMedical.denominationNotice}
            </Text>
            {props.option.dispositifMedical.declarationArret === "oui" ? (
              <Badge variant="dot" color="red" size="xs" radius="sm">
                <Text size="xs" tt="capitalize">
                  arrêt
                </Text>
              </Badge>
            ) : (
              <Badge variant="dot" color="green" size="xs" radius="sm">
                <Text size="xs" tt="capitalize">
                  disponible
                </Text>
              </Badge>
            )}
          </Group>
        );
      }}
    />
  );
}

export default SearchDispositifMedical;
