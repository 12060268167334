import React, { useEffect, useState } from "react";
import { Select, Loader } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { getPatients } from "../api/axiosClient";

function SearchPatientEvent({ handlePatientClick }) {
  const [data, setData] = useState([]);
  const [loadingSearchEvent, setloadingSearchEvent] = useState(false);

  useEffect(() => {
    getPatients().then((patients) => {
      const formattedData = patients.map((patient) => ({
        value: `${patient.id} `,
        label: `${patient.NomNaissance} ${patient.ListePrenom}`,
        patient: patient,
      }));
      setData(formattedData);
      setloadingSearchEvent(false);
    });
  }, []);

  return (
    <Select
      searchable
      placeholder="Rechercher un Patient"
      data={data}
      rightSection={loadingSearchEvent && <Loader size="sm" />}
      maxDropdownHeight={200}
      onChange={(label, value) => handlePatientClick(value)}
      comboboxProps={{
        transitionProps: { transition: "pop", duration: 200 },
        shadow: "md",
        width: "300px",
      }}
      style={{ width: "300px", shadow: "md" }}
      leftSection={<IconSearch size="1rem" style={{ display: "block" }} />}
    />
  );
}

export default SearchPatientEvent;