import { jsPDF } from "jspdf";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useStore } from "../Store";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { IconPrinter } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import { Group, Button } from "@mantine/core";
import { QRCodeCanvas } from "qrcode.react";
import "jspdf-barcode";

export const GenerateOrdonnance = ({ type, data, dateType }) => {
  const id = "01S2TBPZ2JCNZZYGHZ";

  const { currentUser, selectedPatientInfo, ordonnanceEntete } = useStore();

  const ordonnanceStoreData = useStore((state) => state.ordonnanceData);
  // console.log({'practitioner':currentUser})

  let ordonnanceData;
  if (type === "duplicata") {
    ordonnanceData = data;
  } else if (type === "ordonnance") {
    ordonnanceData = ordonnanceStoreData;
  }

  const FormatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMMM yyyy", { locale: fr });
  };

  const [date, setDate] = useState(new Date());
  useEffect(() => {
    if (type === "duplicata") {
      setDate(new Date(dateType));
    }
  }, [type, dateType]);

  const generatePDF = useCallback(() => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 18;
    let yPos = 50;
    const lineSpacing = 5;
    const itemSpacing = 10;
    const headerSpacing = 15;
    const footerHeight = 10;
    const textHeight = 6;

    // Fonction pour ajouter l'en-tête
    const addHeader = () => {
      doc.setFontSize(16);
      doc.setFont("Optima", "bold");
      doc.text(
        "Dr. " + currentUser.first_name + " " + currentUser.last_name,
        15,
        15,
        { align: "left" }
      );
      doc.setFont("Optima", "bolditalic");
      doc.setFontSize(12);
      doc.text(ordonnanceEntete?.specialite || "", 15, 20, { align: "left" });
      doc.setFont("Optima", "normal");
      doc.setFontSize(11);
      doc.text(ordonnanceEntete?.info_ordo || "", 15, 25, {
        align: "left",
      });
      doc.text(ordonnanceEntete?.addresse || "", 15, 30, { align: "left" });
      doc.text(ordonnanceEntete?.fixe || "", 15, 35, { align: "left" });
      doc.setFont("Optima", "bold");
      doc.text(ordonnanceEntete?.rendez_vous || "", 15, 40, { align: "left" });
      doc.setFont("Times", "italic");
      yPos += 5;

      // Ajout d'un code-barres
      doc.setFont("Optima", "normal");
      doc.setFontSize(10);
      doc.text("RPPS", 125, 16, { align: "center" });
      doc.barcode(ordonnanceEntete?.RPPS || "", {
        type: "CODE128",
        fontSize: 40,
        textColor: "#000000",
        x: 125,
        y: 30,
        textOptions: { align: "center", fontSize: 12 },
      });
      doc.setFont("Optima", "normal");
      doc.setFontSize(11);
      doc.text(ordonnanceEntete?.RPPS || "", 125, 34, { align: "center" });

      // Ajout d'un code-barres
      doc.setFont("Optima", "normal");
      doc.setFontSize(10);
      doc.text("AM", 175, 16, { align: "center" });
      doc.barcode(ordonnanceEntete?.ADELI || "", {
        fontSize: 40,
        textColor: "#000000",
        x: 175,
        y: 30,
        textOptions: { align: "center" },
      });
      doc.setFont("Optima", "normal");
      doc.setFontSize(11);
      doc.text(ordonnanceEntete?.ADELI || "", 175, 34, { align: "center" });
    };

    // Fonction pour ajouter le pied de page
    const addFooter = () => {
      const qrCodeURL1 = document.getElementById("qrCodeEl");
      doc.setFontSize(10);
      doc.text(
        "Page " + doc.internal.getCurrentPageInfo().pageNumber,
        pageWidth -15,
        pageHeight - 10,
        { align: "center" }
      );

      doc.addImage(qrCodeURL1, "PNG", 12, pageHeight - 30, 20, 20);
      doc.setFont("Optima", "normal");
      doc.setFontSize(9);
      doc.text("e-prescription N°",  22, pageHeight - 7, { align: "center" });
      doc.text(id, 22, pageHeight - 3, { align: "center" });
    };

    // Fonction pour ajouter une nouvelle page
    const addNewPage = () => {
      doc.addPage();
      addHeader();
      yPos = 50;
    };

    // Fonction pour ajouter le nom du patient et la date
    const addPatientNameAndDate = () => {
      doc.setFont("Times", "normal");
      doc.setFontSize(12);
      addText(`Fait le ${FormatDate(date)}`, margin);
      if (type === "duplicata") {
        doc.setFont("Times", "bold");
        doc.setFontSize(27);
        doc.setTextColor("red");
        doc.text("DUPLICATA", 110, 50, {
          align: "left",
        });
        doc.setTextColor("black");
      }
      yPos += 5;

      const patientName = `Monsieur ${selectedPatientInfo.NomNaissance} ${selectedPatientInfo.ListePrenom}`;
      const patientNameX = pageWidth / 2;

      doc.setFont("Times", "bold");
      doc.setFontSize(15);
      doc.text(patientName, patientNameX, yPos, { align: "center" });

      // Ajouter un soulignement sous le nom du patient
      const textWidth = doc.getTextWidth(patientName);
      doc.line(
        patientNameX - textWidth / 2,
        yPos + 1,
        patientNameX + textWidth / 2,
        yPos + 1
      ); // +2 pour positionner la ligne juste en dessous du texte
      yPos += 10;
    };

    // Fonction pour ajouter du texte avec gestion automatique des retours à la ligne et nouvelles pages
    const addText = (
      text,
      x,
      fontSize = 12,
      maxWidth = pageWidth - 2 * margin
    ) => {
      doc.setFontSize(fontSize);
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line) => {
        if (yPos + textHeight > pageHeight - footerHeight - itemSpacing) {
          addNewPage();
          yPos += 5;
          addPatientNameAndDate();
          yPos += 5;
        }
        doc.text(line, x, yPos);
        yPos += textHeight;
      });
    };
    // Ajout de l'en-tête
    addHeader();

    // Ajout du nom du patient et de la date en haut de la première page
    addPatientNameAndDate();

    // Séparation des données de l'ordonnance en deux catégories
    const tttAldTrue = ordonnanceData.filter((item) => item.tttAld === true);
    const tttAldFalse = ordonnanceData.filter((item) => item.tttAld === false);

    let index = 1; // Initialisation de l'index global

    // Ajout des données tttAld === true avec les séparateurs et le texte
    if (tttAldTrue.length > 0) {
      doc.setFontSize(10);
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 3.5;
      doc.text(
        "Prescriptions relatives au traitement de l'affection de longue durée reconnue (liste ou hors liste)",
        pageWidth / 2,
        yPos,
        { align: "center" }
      );
      yPos += 5;
      doc.text("(AFFECTIONS EXONERANTE)", pageWidth / 2, yPos, {
        align: "center",
      });
      yPos += 1.5;
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 10;

      tttAldTrue.forEach((prescription) => {
        // Vérifier l'espace restant sur la page avant d'ajouter un nouvel élément
        if (yPos + textHeight * 2 > pageHeight - footerHeight - itemSpacing) {
          addNewPage();
          yPos += 5;
          addPatientNameAndDate();
          yPos += 5;
        }

        doc.setFont("Times", "bold");
        doc.setFontSize(14);
        addText(
          `${index}. ${prescription.dci} (${prescription.nom_commercial})`,
          margin,
          12,
          pageWidth - 2 * margin
        );
        doc.setFont("Times", "italic");
        doc.setFontSize(10);
        addText(prescription.commentaire, margin+5, 12, pageWidth - 2 * margin);
        yPos += 5; // Espacement
        index++; // Incrémentation de l'index global
      });

      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 3.5;
      doc.setFontSize(10);
      doc.setFont("Times", "bold");
      doc.text(
        "Prescriptions SANS RAPPORT avec l'affection de longue durée",
        pageWidth / 2,
        yPos,
        { align: "center" }
      );
      yPos += 5;
      doc.text("(MALADIES INTERCURRENTES)", pageWidth / 2, yPos, {
        align: "center",
      });
      yPos += 1.5;
      doc.line(margin, yPos, pageWidth - margin, yPos);
      yPos += 10;
    }
    if (tttAldTrue.length === 0) {
      yPos += 5;
    }
    // Ajout des données tttAld === false
    tttAldFalse.forEach((prescription) => {
      // Vérifier l'espace restant sur la page avant d'ajouter un nouvel élément
      if (yPos + textHeight * 2 > pageHeight - footerHeight - itemSpacing) {
        addNewPage();
        yPos += 5;
        addPatientNameAndDate();
        yPos += 5;
      }
      doc.setFont("Times", "bold");
      doc.setFontSize(14);
      addText(
        `${index}. ${prescription.dci} (${prescription.nom_commercial})`,
        margin,
        12,
        pageWidth - 2 * margin
      );
      doc.setFont("Times", "italic");
      doc.setFontSize(10);
      addText(prescription.commentaire, margin+5, 12, pageWidth - 2 * margin);
      yPos += 5; // Espacement
      index++; // Incrémentation de l'index global
    });

    // Ajout du pied de page sur toutes les pages
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addFooter();
    }

    // Générer le blob et ouvrir dans un nouvel onglet
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
    // doc.autoPrint();
  }, [ordonnanceData, selectedPatientInfo, date, type]);

  return (
    <>
      <Group>
        {type === "ordonnance" && (
          <DateInput
            variant="filled"
            valueFormat="DD/MM/YYYY"
            size="xs"
            radius="12px"
            placeholder="Input placeholder"
            defaultValue={new Date()}
            value={date}
            onChange={setDate}
          />
        )}
        <Button
          className="printButton"
          variant="outline"
          size="xs"
          color="#94ADA7"
          radius="12px"
          style={{ boxShadow: "0 2px 5px #bebebe" }}
          onClick={generatePDF}
        >
          <IconPrinter color="#94ADA7" size={19} />
        </Button>
        
      </Group>
      <div style={{ display: "none" }}>
        <QRCodeCanvas id="qrCodeEl" value={id} size={128}   minVersion={5}/>
      </div>
    </>
  );
};


