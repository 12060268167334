import React, { useEffect, useState, useCallback } from "react";
import { Group, Text, Button } from "@mantine/core";
import { IconDropletFilled, IconPlus, IconRadioactiveFilled, IconSearch } from "@tabler/icons-react";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import {client} from "../api/axiosClient"



export function AutoSearchRadioPresc() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const radiologieData = useStore((state) => state.radiologieData);
  const setRadiologieData = useStore((state) => state.setRadiologieData);
  const examensComplementaires = useStore(
    (state) => state.examensComplementaires
  );
  const { setActive, setActiveTab } = useStore();

  const postPrescription = async (liste_radio) => {
   console.log(liste_radio)
    if (!Array.isArray(liste_radio) || liste_radio.length === 0) {
      console.error("No valid biological exams to prescribe:", liste_radio);
      return;
    }

    setLoading(true);
    try {  
        for (const value of liste_radio || []) {
          await handleRadiologieClick(value);
        }
      }
     catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
 
  // Choisir une radiologie et l'enregitrer dasn l'ordonnance en cours
  const handleRadiologieClick = async (value) => {
    setLoading(true);
    const RadiologieData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      categoryContent: String("Radiologie").trim(),
      ServiceId: parseInt(value.id, 10),
      ServiceContent: [{ radiologie: value, tttAld: false }],
    };


    try {
      if (Array.isArray(radiologieData)) {
        const existingIndex = radiologieData.findIndex(
          (item) => item.ServiceId === value.id
        );

        if (existingIndex !== -1) {
            const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <Text fw={700} size="xs">{value.examen} déjà Prescrit </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,
          });
        } else {
          const response = await client.post(
           "api/service-request/",
          RadiologieData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

          if (response.status === 201) {
            // Mettre à jour l'état local avec l'ID retourné par l'API
            radiologieData.push({ ...RadiologieData, id: response.data.id });
            console.log(radiologieData)
            setRadiologieData(radiologieData);
            console.log("Ordonnance mise à jour");
    
        }
      
      };
    }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    (Array.isArray(examensComplementaires?.examens?.radiologie?.prescrire) || examensComplementaires?.examens?.radiologie?.prescrire.length === 0) && (
      <Button 
        className='renouvelerButton'
        color="#94ADA7"
        radius="12px"
        size="xs"
        onClick={() => {
          const listeRadio = examensComplementaires?.examens?.radiologie?.prescrire || []; // Fallback to an empty array if not defined
          postPrescription(listeRadio);
          setActive("radiologie");
          setActiveTab("radiologie");
        }} 
        loading={loading} 
        disabled={loading}
      >
        <IconPlus size={16}/>
        <IconRadioactiveFilled size={18}/>
      </Button>
    )
  );
}

