import { useEffect, useState } from "react";
import { useStore } from "../Store";
import { v4 as uuidv4 } from 'uuid'; // Assurez-vous d'avoir installé uuid: npm install uuid
import {client} from "../api/axiosClient"


async function getAntecedents(query = "") {
  try {
    const response = await client.get(`api/icd-11/autoSearch/?antecedent=${query}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { status: 404 };
    }
    throw error;
  }
}

export function IsearchAntecedents({ handleAntecedentClick }) {
  const [loading, setLoading] = useState(false);
  
  const observationValues = useStore((state) => state.observationValues);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const setSelectedPatientInfo = useStore((state) => state.setSelectedPatientInfo);

  useEffect(() => {
    const fetchAntecedents = async () => {
      if (!observationValues.Antecedents) {
        return;
      }
      try {
        setLoading(true);
        
        const fetchAndProcessAntecedents = async (antecedents, existingAntecedents) => {
          if (!Array.isArray(antecedents)) {
            console.error('Expected antecedents to be an array, got:', antecedents);
            return [];
          }
          const results = await Promise.all(
            antecedents.map(async (item) => {
              const response = await getAntecedents(item.sfn);
              return { antecedentTranscription: item.sfn, response: response };
            })
          );
          return results.reduce((acc, result) => {
            const { antecedentTranscription, response } = result;
            console.log (antecedentTranscription)
            if (response.status === 404 && antecedentTranscription) {
              console.log ()
              const newAntecedent = {
                id: uuidv4(),
                Title: antecedentTranscription
              };
              return [...acc, { [newAntecedent.id]: newAntecedent }];
            }
            if (response && response.antecedents) {
              const icd11Data = response.antecedents;
              const isDuplicate = existingAntecedents.some(ant => ant[icd11Data.id]);
              if (!isDuplicate) {
                return [...acc, { [icd11Data.id]: icd11Data }];
              }
            }
            return acc;
          }, []);
        };

        const newAntecedentsMedicaux = observationValues.Antecedents.Medicaux && Array.isArray(observationValues.Antecedents.Medicaux) ? 
          await fetchAndProcessAntecedents(observationValues.Antecedents.Medicaux, selectedPatientInfo.antecedentsMedicaux || []) : [];
        const newAntecedentsChirurgicaux = observationValues.Antecedents.Chirurgicaux && Array.isArray(observationValues.Antecedents.Chirurgicaux) ? 
          await fetchAndProcessAntecedents(observationValues.Antecedents.Chirurgicaux, selectedPatientInfo.antecedentsChirurgicaux || []) : [];
        const newAntecedentsFamiliaux = observationValues.Antecedents.Familiaux && Array.isArray(observationValues.Antecedents.Familiaux) ? 
          await fetchAndProcessAntecedents(observationValues.Antecedents.Familiaux, selectedPatientInfo.antecedentsFamiliaux || []) : [];
        const newAllergies = observationValues.Antecedents.Allergies && Array.isArray(observationValues.Antecedents.Allergies) ?
          await fetchAndProcessAntecedents(observationValues.Antecedents.Allergies, selectedPatientInfo.allergies || []) : [];

        const updatedFields = {
          antecedentsMedicaux: [
            ...(Array.isArray(selectedPatientInfo.antecedentsMedicaux) 
                ? selectedPatientInfo.antecedentsMedicaux 
                : []),
            ...newAntecedentsMedicaux,
          ],
          antecedentsChirurgicaux: [
            ...(Array.isArray(selectedPatientInfo.antecedentsChirurgicaux) 
                ? selectedPatientInfo.antecedentsChirurgicaux 
                : []),
            ...newAntecedentsChirurgicaux,
          ],
          antecedentsFamiliaux: [
            ...(Array.isArray(selectedPatientInfo.antecedentsFamiliaux) 
                ? selectedPatientInfo.antecedentsFamiliaux 
                : []),
            ...newAntecedentsFamiliaux,
          ],
          allergies: [
            ...(Array.isArray(selectedPatientInfo.allergies) 
                ? selectedPatientInfo.allergies 
                : []),
            ...newAllergies,
          ],
        };

        if (newAntecedentsMedicaux.length > 0 || newAntecedentsChirurgicaux.length > 0 || newAntecedentsFamiliaux.length > 0 || newAllergies.length > 0) {
          const response = await client.patch(
            `api/patients/${selectedPatientInfo.id}/`,
            updatedFields
          );
          if (response.status === 200) {
            setSelectedPatientInfo(response.data);
          } else {
            console.log("L'enregistrement des antécédents n'a pas pu être fait");
          }
        }
      } catch (error) {
        console.error("Failed to fetch or add antecedents:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAntecedents();
  }, [observationValues]);

}
