// components/OrdonnanceColumn.js

import React from "react";
import "../App.css";
import {
  Grid,
  Tabs,
  SegmentedControl,
  Card,
  Stack,
  Group,
  Title,
  Divider,
  Text,
  Badge,
  Button,
  Tooltip,
  Switch,
  CloseButton
} from "@mantine/core";
import {
  IconX,
  IconRadioactiveFilled,
  IconDropletFilled,
  IconPillFilled,
  IconBandage,

} from "@tabler/icons-react";
import { useStore } from "../Store";
import {GenerateOrdonnance} from "../components/printOrdonnance";
import { SaveOrdonnanceType } from "../components/AddOrdonnanceType";
import { ShowOrdonnanceType } from "../components/AddOrdonnanceType";
import {SaveServiceRequestType} from "../components/AddServiceRequestType"
import {ShowServiceRequestType} from "../components/AddServiceRequestType"
import { Editor } from "../components/EssaiLexical";
import GenerateOrdonnanceBiologie from "../components/printBiologie";
import GenerateOrdonnanceRadiologie from "../components/printRadiologie.js";
import SearchDispositifMedical from "../components/SearchDispositifMedical.js";
import GenerateOrdonnanceDispositifMedical from "../components/printDispositifMedical.js";
import { GenerateOrdonnanceLibre } from "../components/printPrescLibre.js";
import SearchMedicaments from "../components/SearchMedicaments";
import SearchBiologies from "../components/SearchBiologie";
import SearchRadiologies from "../components/SearchRadiologie";
import { client } from "../api/axiosClient";

export function OrdonnanceColumn() {
  const {
    ordonnanceData,
    biologieData,
    radiologieData,
    ordonnanceLibreData,
    dispositifMedicalData,
    active,
    activeTab,
    data,
    setActive,
    setActiveTab,
    setMiddleActive,
    setMiddleActiveTab,
    setSelectedMedicamentDetail,
    setBiologieData,
    setRadiologieData,
    setDispositifMedicalData,
    setOrdonnanceData,
    setordonnanceLibreData,
    newEditorState,
    setNewEditorState,
    editorState

  } = useStore();


  const handlePrescriptionControlChange = (value) => {
    setActive(value);
    setActiveTab(value);
  };

  const handleMedicamentControlChange = (item) => {
    setMiddleActive("Medicament");
    setMiddleActiveTab("Medicament");
    setSelectedMedicamentDetail(item);
  };


  const deleteMedicament = async (id) => {
    // Mettre à jour immédiatement l'état pour supprimer l'élément du DOM
    const prevData = ordonnanceData.filter((item) => item.id !== id);
    setOrdonnanceData(prevData);

    try {
      const response = await client.delete(`api/medication-requests/${id}/`);
      if (response.status === 204) {
        console.log("médicament supprimé");
        setSelectedMedicamentDetail(null);
      } else console.log(response);
    } catch (error) {
      console.error("Erreur lors de la suppression du médicament:", error);
    }
  };

  const deleteBiologie = async (id) => {
    // Mettre à jour immédiatement l'état pour supprimer l'élément du DOM
    const prevData = biologieData.filter((item) => item.id !== id);
    setBiologieData(prevData);

    try {
      const response = await client.delete(`api/service-request/${id}/`);
      if (response.status === 204) {
        console.log("biologie supprimée");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du médicament:", error);
    }
  };

  const deleteRadiologie = async (id) => {
    // Mettre à jour immédiatement l'état pour supprimer l'élément du DOM
    const prevData = radiologieData.filter((item) => item.id !== id);
    setRadiologieData(prevData);

    try {
      const response = await client.delete(`api/service-request/${id}/`);
      if (response.status === 204) {
        console.log("radiologie supprimée");
      }
    } catch (error) {
      console.error(
        "Erreur lors de la suppression de l'examen de radiologie:",
        error
      );
    }
  };

  const deleteDispositifMedical = async (id) => {
    // Mettre à jour immédiatement l'état pour supprimer l'élément du DOM
    const prevData = dispositifMedicalData.filter((item) => item.id !== id);
    setDispositifMedicalData(prevData);

    try {
      const response = await client.delete(`api/service-request/${id}/`);
      if (response.status === 204) {
        console.log("DM supprimée");
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du DM:", error);
    }
  };
  // Fonction pour mettre à jour tttAld
  const handleBiologieSwitchChange = (serviceId, index, checked) => {
    const updatedData = biologieData.map((item) => {
      if (item.ServiceId === serviceId) {
        const updatedServiceContent = [...item.ServiceContent];
        updatedServiceContent[index].tttAld = checked; // Mettre à jour tttAld selon l'état du switch
        return { ...item, ServiceContent: updatedServiceContent };
      }
      return item;
    });
    setBiologieData(updatedData); // Mettre à jour l'état
  };

  const handleRadiologieSwitchChange = (serviceId, index, checked) => {
    const updatedData = radiologieData.map((item) => {
      if (item.ServiceId === serviceId) {
        const updatedServiceContent = [...item.ServiceContent];
        updatedServiceContent[index].tttAld = checked; // Mettre à jour tttAld selon l'état du switch
        return { ...item, ServiceContent: updatedServiceContent };
      }
      return item;
    });
    setRadiologieData(updatedData); // Mettre à jour l'état
    console.log(radiologieData);
  };

  const handleDispositifMedicalSwitchChange = (serviceId, index, checked) => {
    const updatedData = dispositifMedicalData.map((item) => {
      if (item.ServiceId === serviceId) {
        const updatedServiceContent = [...item.ServiceContent];
        updatedServiceContent[index].tttAld = checked; // Mettre à jour tttAld selon l'état du switch
        return { ...item, ServiceContent: updatedServiceContent };
      }
      return item;
    });
    setDispositifMedicalData(updatedData); // Mettre à jour l'état
    console.log(dispositifMedicalData);
  };


  return (
    <Grid.Col span={4.8} className="ordonnanceGridCol">
      <Divider
        my="xs"
        label="Prescription des Ordonnances"
        labelPosition="center"
      />
      <Tabs value={activeTab} onChange={setActiveTab} radius="12px">
        <SegmentedControl
          data={data}
          value={active}
          onChange={handlePrescriptionControlChange}
          color="#94ADA7"
          radius="12px"
          size="xs"
          withItemsBorders={false}
          transitionDuration={500}
          transitionTimingFunction="linear"
          fullWidth
          style={{ marginBottom: 20 }}
        />
        <Tabs.Panel value="medicaments">
          <Card
            className="OrdoMedCard"
            shadow="lg"
            radius="12px"
            p="md"
            withBorder={true}
          >
            <Stack
              className="medicamentsList"
              align="stretch"
              justify="flex-start"
              gap="xs"
            >
              <Group justify="space-between">
                <Group>
                  <Title size="h4">&nbsp;Médicaments</Title>{" "}
                  <ShowOrdonnanceType />
                </Group>
                <Group>
                  <GenerateOrdonnance type="ordonnance" />
                  {ordonnanceData && ordonnanceData.length > 0 && (
                    <SaveOrdonnanceType />
                  )}
                </Group>
              </Group>
              <SearchMedicaments className="SearchMedicaments" />
              {ordonnanceData?.map?.((item, index) => (
                <Button
                  className="medicamentsButton"
                  justify="space-between"
                  size="xs"
                  variant="outline"
                  radius="12px"
                  color="#94ADA7"
                  onClick={() => {
                    handleMedicamentControlChange(item);
                  }}
                  rightSection={
                    <CloseButton
                      className="CloseButton"
                      zIndex="3000"
                      onClick={() => {
                        deleteMedicament(item.id);
                      }}
                      icon={<IconX size={18} stroke={1.5} color="red" />}
                    />
                  }
                  fullWidth
                >
                  <Text
                    className="TextMedicament"
                    size="xs"
                    color="black"
                    truncate="end"
                    lineClamp={1}
                  >
                    <IconPillFilled
                      className="MedicamentIcon"
                      color="#94ADA7"
                      style={{ verticalAlign: "middle" }}
                    />
                    &nbsp;&nbsp;
                    <strong>{item.nom_commercial}</strong> {" ("}
                    {item.dci}
                    {")"}
                  </Text>
                </Button>
              ))}
            </Stack>
          </Card>

        </Tabs.Panel>

        <Tabs.Panel value="dispositifsMedicaux">
          <Card
            className="dispositifMedicalCard"
            shadow="lg"
            radius="12px"
            p="md"
            withBorder={true}
          >
            <Stack
              className="DMList"
              align="stretch"
              justify="flex-start"
              gap="xs"
            >
              <Group justify="space-between">
                <Group>
                <Title size="h4">
                  &nbsp;Dispositifs Médicaux
                </Title>
                <ShowServiceRequestType ServiceType={"DispositifMedical"} />
                </Group>
                <Group>
                <GenerateOrdonnanceDispositifMedical type="ordonnance" />
                {dispositifMedicalData && dispositifMedicalData.length > 0 && (
                <SaveServiceRequestType ServiceType={"DispositifMedical"}/>
                )}
                </Group>
              </Group>
              <SearchDispositifMedical className="SearchDispositifMedical" />
              {dispositifMedicalData?.map?.((item, index) => (
                <Button
                  key={item.id}
                  className="medicamentsButton"
                  justify="space-between"
                  size="xs"
                  variant="outline"
                  radius="12px"
                  color="#94ADA7"
                  // onClick={() => {
                  //   handleDispositifMedicalControlChange(item);
                  // }}
                  rightSection={
                    <CloseButton
                      className="CloseButton"
                      zIndex="3000"
                      onClick={() => {
                        deleteDispositifMedical(item.id);
                      }}
                      icon={<IconX size={18} stroke={1.5} color="red" />}
                    />
                  }
                  fullWidth
                >
                  <Text
                    className="TextDispositifMedical"
                    size="xs"
                    c="black"
                    truncate="end"
                    lineClamp={1}
                  >
                    {" "}
                    <Group>
                      <IconBandage
                        className="MedicamentIcon"
                        color="#94ADA7"
                        style={{
                          verticalAlign: "middle",
                        }}
                      />

                      {item.ServiceContent?.map?.((service, index) => (
                        <>
                          <strong>
                            {service?.label?.denominationCommerciale}
                          </strong>
                          <Text>{service?.label?.denominationNotice}</Text>
                          {service?.label?.declarationArret === "oui" ? (
                            <Badge
                              variant="dot"
                              color="red"
                              size="xs"
                              radius="sm"
                            >
                              <Text size="xs" tt="capitalize">
                                arrêt
                              </Text>
                            </Badge>
                          ) : (
                            <Badge
                              variant="dot"
                              color="green"
                              size="xs"
                              radius="sm"
                            >
                              <Text size="xs" tt="capitalize">
                                disponible
                              </Text>
                            </Badge>
                          )}
                          <Switch
                            size="xs"
                            onLabel="ALD"
                            offLabel="ALD"
                            checked={service.tttAld}
                            onChange={(event) =>
                              handleDispositifMedicalSwitchChange(
                                item.ServiceId,
                                index,
                                event.currentTarget.checked
                              )
                            }
                          />
                        </>
                      ))}
                    </Group>
                  </Text>
                </Button>
              ))}
            </Stack>
          </Card>
        </Tabs.Panel>

        <Tabs.Panel value="biologie">
          <Card
            className="OrdoBioCard"
            shadow="lg"
            radius="12px"
            p="md"
            withBorder={true}
          >
            <Stack
              className="biologiesList"
              align="stretch"
              justify="flex-start"
              gap="xs"
            >
              <Group justify="space-between">
                <Group>
                <Title size="h4">&nbsp;Biologie</Title>
                <ShowServiceRequestType ServiceType={"Biologie"} />
                </Group>
               <Group>
               <GenerateOrdonnanceBiologie type="ordonnance" />
               {biologieData && biologieData.length > 0 && (
               <SaveServiceRequestType ServiceType={"Biologie"} />
               )}
               </Group>
                
                
              </Group>
              <SearchBiologies
                className="SearchBiologies"
                // handleBiologieClick={handleBiologieClick}
              />

              {biologieData?.map?.((item, index) => (
                <Button
                  className="biologiesButton"
                  justify="space-between"
                  size="xs"
                  variant="outline"
                  radius="12px"
                  color="#94ADA7"
                  // leftSection={<IconShieldCheckered size={14} />}
                  rightSection={
                    <CloseButton
                      onClick={() => {
                        deleteBiologie(item.id);
                      }}
                      icon={<IconX size={18} stroke={1.5} color="red" />}
                    />
                  }
                  fullWidth
                >
                  <Text size="xs" color="black" truncate="end" lineClamp={1}>
                    {" "}
                    <Group>
                      <IconDropletFilled
                        className="MedicamentIcon"
                        color="red"
                        style={{
                          verticalAlign: "middle",
                          opacity: "0.6",
                        }}
                      />
                      {item.ServiceContent?.map?.((biologie, index) => (
                        <>
                          <strong>{biologie.label}</strong>
                          <Switch
                            size="xs"
                            onLabel="ALD"
                            offLabel="ALD"
                            checked={biologie.tttAld}
                            onChange={(event) =>
                              handleBiologieSwitchChange(
                                item.ServiceId,
                                index,
                                event.currentTarget.checked
                              )
                            }
                          />
                        </>
                      ))}
                    </Group>
                  </Text>
                </Button>
              ))}
            </Stack>
          </Card>
        </Tabs.Panel>

        <Tabs.Panel value="radiologie">
          <Card
            className="OrdoRadioCard"
            shadow="lg"
            radius="12px"
            p="md"
            withBorder={true}
          >
            <Stack
              className="radiologiesList"
              align="stretch"
              justify="flex-start"
              gap="xs"
            >
              <Group justify="space-between">
                <Group>
                <Title size="h4">&nbsp;Radiologie</Title>
                <ShowServiceRequestType ServiceType={"Radiologie"}/>
                </Group>
                <Group>
                <GenerateOrdonnanceRadiologie type="ordonnance" />
                {radiologieData && radiologieData.length > 0 && (
                <SaveServiceRequestType ServiceType={"Radiologie"}/>
                )}
                </Group>
              </Group>
              <SearchRadiologies />
              {radiologieData?.map?.((item, index) => (
                <Button
                  className="radiolgieButton"
                  justify="space-between"
                  size="xs"
                  variant="outline"
                  radius="12px"
                  color="#94ADA7"
                  // leftSection={<IconShieldCheckered size={14} />}
                  rightSection={
                    <CloseButton
                      onClick={() => {
                        deleteRadiologie(item.id);
                      }}
                      icon={<IconX size={18} stroke={1.5} color="red" />}
                    />
                  }
                  fullWidth
                >
                  {" "}
                  <Group>
                    <IconRadioactiveFilled
                      className="RadiolgieIcon"
                      color="red"
                      style={{
                        verticalAlign: "middle",
                        opacity: "0.6",
                      }}
                    />
                    {item.ServiceContent?.map(
                      (service, index) =>
                        service.radiologie && (
                          <>
                            <Tooltip
                              key={index}
                              label={
                                service.radiologie.instructions &&
                                service.radiologie.instructions.length > 0 ? (
                                  <Stack gap="xs">
                                    <Text size="sm">
                                      {service.radiologie.instructions.map(
                                        (instruction, index) => (
                                          <div key={index}>{instruction}</div>
                                        )
                                      )}
                                    </Text>
                                    <Text size="xs">
                                      Source: Société Française de Radiologie et
                                      d'Imagerie Médicale ( Aide à la demande
                                      d’examens de radiologie et imagerie
                                      médicale (ADERIM))
                                    </Text>
                                  </Stack>
                                ) : (
                                  <Text size="sm">
                                    No instructions available
                                  </Text>
                                )
                              }
                              color="#94ADA7"
                              withArrow
                              multiline
                              w={"20%"}
                              position="right"
                              radius="12px"
                              arrowOffset={25}
                              arrowSize={8}
                              transitionProps={{
                                transition: "pop",
                                duration: 500,
                              }}
                              openDelay={1000}
                            >
                              <Group>
                                <Text fw={550} size="xs" c="black">
                                  {service.radiologie.examen}
                                </Text>
                                <Text
                                  size="xs"
                                  c="black"
                                  truncate="end"
                                  lineClamp={1}
                                >
                                  {service.radiologie.maladie}{" "}
                                  {service.radiologie.contexte
                                    ? `(${service.radiologie.contexte})`
                                    : ""}
                                </Text>
                                <Switch
                                  size="xs"
                                  onLabel="ALD"
                                  offLabel="ALD"
                                  checked={service.tttAld}
                                  onChange={(event) =>
                                    handleRadiologieSwitchChange(
                                      item.ServiceId,
                                      index,
                                      event.currentTarget.checked
                                    )
                                  }
                                />
                              </Group>
                            </Tooltip>
                          </>
                        )
                    )}
                  </Group>
                </Button>
              ))}
            </Stack>
          </Card>
        </Tabs.Panel>

        <Tabs.Panel value="autres">
          <Card
            className="OrdoLibreCard"
            shadow="lg"
            radius="12px"
            p="md"
            withBorder={true}
          >
           

                   
            <GenerateOrdonnanceLibre />

          
        
            <Editor />
          </Card>
        </Tabs.Panel>
      </Tabs>
    </Grid.Col>
  );
}


