
import {client} from "../api/axiosClient"

export const handleAntecedentClickInternal = async (value, antecedentSearchType, selectedPatientInfo, setSelectedPatientInfo, setShowAntecedentsSearch) => {
    if (antecedentSearchType === "Medical") {
      try {
        const updatedFields = {
          antecedentsMedicaux: [
            ...selectedPatientInfo.antecedentsMedicaux,
            { [value.icd11Data.id]: value.icd11Data },
          ],
        };

        const response = await client.patch(
          `api/patients/${selectedPatientInfo.id}/`,
          updatedFields
        );

        if (response.status === 200) {
          setSelectedPatientInfo(response.data);
        } else {
          console.log("L'enregistrement des antécédents n'a pas pu être fait");
        }
      } catch (error) {
        console.log(error);
      }

      setShowAntecedentsSearch(false);
    }
    if (antecedentSearchType === "Chirurgical") {
      try {
        const updatedFields = {
          antecedentsChirurgicaux: [
            ...selectedPatientInfo.antecedentsChirurgicaux,
            { [value.icd11Data.id]: value.icd11Data },
          ],
        };

        const response = await client.patch(
          `api/patients/${selectedPatientInfo.id}/`,
          updatedFields
        );

        if (response.status === 200) {
          setSelectedPatientInfo(response.data);
        } else {
          console.log("L'enregistrement des antécédents n'a pas pu être fait");
        }
      } catch (error) {
        console.log(error);
      }
      setShowAntecedentsSearch(false);
    }
    if (antecedentSearchType === "Familial") {
      try {
        const updatedFields = {
          antecedentsFamiliaux: [
            ...selectedPatientInfo.antecedentsFamiliaux,
            { [value.icd11Data.id]: value.icd11Data },
          ],
        };

        const response = await client.patch(
          `api/patients/${selectedPatientInfo.id}/`,
          updatedFields
        );

        if (response.status === 200) {
          setSelectedPatientInfo(response.data);
        } else {
          console.log("L'enregistrement des antécédents n'a pas pu être fait");
        }
      } catch (error) {
        console.log(error);
      }
      setShowAntecedentsSearch(false);
    }
    if (antecedentSearchType === "Allergie") {
      try {
        const updatedFields = {
          allergies: [
            ...selectedPatientInfo.allergies,
            { [value.icd11Data.id]: value.icd11Data },
          ],
        };

        const response = await client.patch(
          `api/patients/${selectedPatientInfo.id}/`,
          updatedFields
        );

        if (response.status === 200) {
          setSelectedPatientInfo(response.data);
        } else {
          console.log("L'enregistrement des antécédents n'a pas pu être fait");
        }
      } catch (error) {
        console.log(error);
      }
      setShowAntecedentsSearch(false);
    }
  };


// interface ICD11Data {
//   id: string;
//   Title?: string;
//   [key: string]: any;
// }

// interface SelectedPatientInfo {
//   id: number;
//   antecedentsMedicaux: { [key: string]: ICD11Data }[];
//   antecedentsChirurgicaux: { [key: string]: ICD11Data }[];
//   antecedentsFamiliaux: { [key: string]: ICD11Data }[];
//   [key: string]: any;
// }

// interface Value {
//   icd11Data: ICD11Data;
// }

// type AntecedentSearchType = "Medical" | "Chirurgical" | "Familial" | "Allergie";

// export const handleAntecedentClickInternal = async (
//   value: Value,
//   antecedentSearchType: AntecedentSearchType,
//   selectedPatientInfo: SelectedPatientInfo,
//   setSelectedPatientInfo: (info: SelectedPatientInfo) => void,
//   setShowAntecedentsSearch: (show: boolean) => void
// ): Promise<void> => {
//   if (antecedentSearchType === "Medical" || antecedentSearchType === "Chirurgical" || antecedentSearchType === "Familial") {
//     try {
//       const antecedentField = `antecedents${antecedentSearchType}s` as keyof SelectedPatientInfo;
//       const existingAntecedents = Array.isArray(selectedPatientInfo[antecedentField]) 
//         ? selectedPatientInfo[antecedentField] 
//         : [];
//       const updatedFields = {
//         [antecedentField]: [
//           ...existingAntecedents,
//           { [value.icd11Data.id]: value.icd11Data },
//         ],
//       };

//       const response = await client.patch<SelectedPatientInfo>(
//         `api/patients/${selectedPatientInfo.id}/`,
//         updatedFields
//       );

//       if (response.status === 200) {
//         setSelectedPatientInfo(response.data);
//       } else {
//         console.log("L'enregistrement des antécédents n'a pas pu être fait");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     setShowAntecedentsSearch(false);
//   }

//   if (antecedentSearchType === "Allergie") {
//     console.log(`allergie : ${value.icd11Data.Title?.replace(/-/g, "")} `);
//     setShowAntecedentsSearch(false);
//   }
// };
 




