// App.js

import {
  MantineProvider,
  Stack,
  Button,
  Image,
  Grid,
  Text,
} from "@mantine/core";
import { useFullscreen } from "@mantine/hooks";
import { useAuth } from "./hooks/useAuth";
import { useStore } from "./Store";
import { NavBar } from "./elements/Navbar";
import { AntecedentsColumn } from "./elements/AntecedentsColumn";
import { MiddleColumn } from "./elements/MiddleColumn";
import { OrdonnanceColumn } from "./elements/OrdonnanceColumn";
import { Calendar } from "./components/Calendar";
import { Mailbox } from "./components/mail";
import { Login } from "./components/Login";
import { Notifications } from "@mantine/notifications";
import "./App.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";

import {
  IconDeviceDesktopUp,
  IconDeviceDesktopDown,
} from "@tabler/icons-react";

export function App() {
  const { toggle, fullscreen } = useFullscreen();
  const { currentUser } = useAuth();
  const {
    showPatientCard,
    selectedPatientInfo,
    showEncouterObjects,
    agendaOpened,
    mailboxOpened,
    loading,
  } = useStore();
  return (
    <MantineProvider>
      <Notifications
        position="bottom-right"
        zIndex={1000}
        transitionDuration={500}
        containerWidth={370}
        notificationMaxHeight={170}
        limit={5}
      />
      <div className="App">
        <Image
          className="ArrierePlan"
          radius="md"
          src="https://www.iavicenne.fr/static/img/arriere_plan.png"
          fit="contain"
        />
        <Image
          className="ArrierePlanBrain"
          radius="md"
          src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
          fit="contain"
        />
        {currentUser ? (
          <div>
            <Button
              className="fullscreenButton"
              variant="filled"
              onClick={toggle}
              color={fullscreen ? "#94ADA7" : "#94ADA7"}
            >
              {fullscreen ? <IconDeviceDesktopDown /> : <IconDeviceDesktopUp />}
            </Button>
            <Stack>
              <NavBar />
              {showPatientCard && (
                <Grid>
                 <AntecedentsColumn />

                  <MiddleColumn />
                  {showEncouterObjects && <OrdonnanceColumn />}
                </Grid>
              )}
              {agendaOpened && <Calendar />}
              {mailboxOpened && <Mailbox />}
            </Stack>
          </div>
        ) : (
          <>
            <Login />
            <Stack>
              <Text
                className="homeTitle"
                fw={500}
                c="#94ADA7"
                style={{
                  fontSize: 60,
                  fontFamily: "Palatino",
                  position: "absolute",
                }}
              >
                iAvicenne
              </Text>
              <Text
                className="homeTitle-2"
                fw={500}
                c="#94ADA7"
                style={{
                  fontSize: 18,
                  fontFamily: "Palatino",
                  position: "absolute",
                }}
              >
                Changement de Paradigme Médical
              </Text>
            </Stack>
          </>
        )}
      </div>
    </MantineProvider>
  );
}
