import React, { useState, useEffect } from 'react';
import { Loader, Group, Text, Image, Button,Tooltip } from "@mantine/core";
import { IconBandageFilled, IconPlus, IconRadioactive, IconTruckReturn } from "@tabler/icons-react";

import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { set } from 'date-fns';

import {client} from "../api/axiosClient"

export const RenewDispositifMedical = ({ encounterId }) => {
  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const dispositifMedicalData = useStore((state) => state.dispositifMedicalData);
  const setDispositifMedicalData = useStore((state) => state.setDispositifMedicalData);
  const { setActive, setActiveTab } = useStore();
  const [loading, setLoading] = useState(false);

 

  const addDispositifMedical = async (value) => {
    setLoading(true);
    const DispositifMedicalData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      categoryContent: String("DispositifMedical").trim(),
      ServiceId: parseInt(value.ServiceId, 10),
      ServiceContent: [{label: value.ServiceContent[0].label, tttAld:false }],
    };


    try {
      if (Array.isArray(dispositifMedicalData)) {
        const existingIndex = dispositifMedicalData.findIndex(
          (item) => item.ServiceId === value.ServiceId
        );

        if (existingIndex !== -1) {
            const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                <Text fw={700} size="xs">{value.ServiceContent[0].label.denominationCommerciale} déjà Prescrit </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,
          });
        } else {
          const response = await client.post(
           "api/service-request/",
          DispositifMedicalData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

          if (response.status === 201) {
            // Mettre à jour l'état local avec l'ID retourné par l'API
            dispositifMedicalData.push({ ...DispositifMedicalData, id: response.data.id });
            console.log(dispositifMedicalData)
            setDispositifMedicalData(dispositifMedicalData);
            console.log("Ordonnance de DispositifMedical mise à jour");
    
           
        }
      
      };
    }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renouvelerOrdo = async () => {
    setLoading(true);
    try {
      const response = await client.get(`api/encounters/${encounterId}/`);

      if (response.status === 200) {
        const ordonnance = response.data.encounterData.ordonnanceDispositifMedical;
        for (const dispositifMedical of ordonnance || []) {
          await addDispositifMedical(dispositifMedical);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
    label={
    
        <Text size="xs">
         Renouveler la DispositifMedical
        </Text>
      
    }
    color="#94ADA7"
    withArrow
    multiline
  
    position="top"
    radius="12px"
    arrowOffset={25}
    arrowSize={8}
    transitionProps={{ transition: "pop", duration: 500 }}
    openDelay={1000}
  >
    <Button 
    className='renouvelerButton'
      color="#94ADA7"
      radius="12px"
      size="xs"
      onClick={() => {
       
        renouvelerOrdo();
        setActive("dispositifsMedicaux")
        setActiveTab("dispositifsMedicaux")
      }} 
      loading={loading} 
      disabled={loading}
    >
      <IconPlus size={16}/>
      <IconBandageFilled size={18}/>
    
    </Button>
    </Tooltip>
   
 
  );
};

