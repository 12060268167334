// components/NavBar.js

import { Group, Button, Text } from "@mantine/core";
import {
  IconDeviceDesktopDown,
  IconDeviceDesktopUp,
  IconMessageFilled,
  IconMailFilled,
  IconHomeFilled,
  IconUserCheck,
  IconStethoscope,
  IconPlus,
  IconStethoscopeOff,
  IconCheck,
  IconUserPlus,
} from "@tabler/icons-react";
import { useFullscreen } from "@mantine/hooks";
import { useStore } from "../Store";
import Logout from "../components/Logout";
import { StructureConsultData } from "../components/StructureClinique";
import { SearchPatient } from "../components/SearchPatient";
import { SearchPatientfromCV } from "../components/SearchPatientFromCV";
import { useEncounter } from "../hooks/useEncounter";
import { IsearchAntecedents } from "../components/AutoSearchAntecedents";

export function NavBar() {
  const { toggle, fullscreen } = useFullscreen();
  const {
    setMiddleActive,
    setMiddleActiveTab,
    selectedPatientInfo,
    currentSessionInfo,
    showEncouterObjects,
    setShowPatientCard,
    setAgendaOpened,
    setMailboxOpened,
    setSelectedPatientInfo,
  } = useStore();
  const { CreateEncounter, SaveEncounter } = useEncounter();

  const handleHomeClick = () => {
    setSelectedPatientInfo("");
    setAgendaOpened(false);
    setMailboxOpened(false);
    setShowPatientCard(false);
  };

  const handlePatientFormControlChange = () => {
    setShowPatientCard(true);
    setMiddleActive("Données Patient");
    setMiddleActiveTab("Données Patient");
    setAgendaOpened(false);
    setMailboxOpened(false);
  };

  const handleNewPatientFormControlChange = () => {
    setShowPatientCard(true);
    setMiddleActive("Nouveau Patient");
    setMiddleActiveTab("Nouveau Patient");
    setAgendaOpened(false);
    setMailboxOpened(false);
  };

  const handleChatClick = () => {
    setShowPatientCard(true);
    setMiddleActive("iAvicenne Chat");
    setMiddleActiveTab("iAvicenne Chat");
    setAgendaOpened(false);
    setMailboxOpened(false);
  };

  return (
    <Group className="navBar" justify="space-between">
      <Group>
        {selectedPatientInfo && currentSessionInfo.subject && (
          <Button
            className="connectedButton"
            variant="filled"
            radius="12px"
            color="#94ADA7"
            onClick={handlePatientFormControlChange}
          >
            <IconUserCheck size={25} />
            <Text size="sm">
              <strong>
                &nbsp;&nbsp;
                {selectedPatientInfo.NomNaissance}{" "}
                {selectedPatientInfo.UsagePrenom}
              </strong>
            </Text>
          </Button>
        )}
        <SearchPatientfromCV />
        <Button
          className="connectedButton"
          variant="filled"
          radius="12px"
          color="#94ADA7"
          onClick={handleNewPatientFormControlChange}
        >
          <IconUserPlus size={25} />
        </Button>
        <StructureConsultData />
        <IsearchAntecedents />
        <SearchPatient />

        {selectedPatientInfo &&
          currentSessionInfo.subject &&
          (showEncouterObjects ? (
            <Button
              className="connectedButton"
              variant="filled"
              color="#94ADA7"
              radius="12px"
              size="sm"
              onClick={SaveEncounter}
            >
              <Group spacing="xs">
                <IconStethoscopeOff size={23} />
                Clôturer
                <IconCheck size={18} />
              </Group>
            </Button>
          ) : (
            <Button
              className="connectedButton"
              variant="filled"
              color="#94ADA7"
              radius="12px"
              size="sm"
              onClick={CreateEncounter}
            >
              <Group spacing="xs">
                <IconStethoscope size={23} />
                Démarrer
                <IconPlus size={18} />
              </Group>
            </Button>
          ))}
      </Group>
      <Group>
        <Button
          className="connectedButton"
          variant="filled"
          radius="12px"
          color="#94ADA7"
          onClick={handleChatClick}
        >
          <IconMessageFilled />
          <Text size="sm">&nbsp;iA Chat</Text>
        </Button>
        <Button
          className="connectedButton"
          variant="filled"
          radius="12px"
          color="#94ADA7"
          onClick={() => {
            setMailboxOpened(true);
            setAgendaOpened(false);
            setShowPatientCard(false);
          }}
        >
          <IconMailFilled />
          <Text size="sm">&nbsp;Mssanté</Text>
        </Button>
        <Button
          className="connectedButton"
          variant="filled"
          radius="12px"
          color="#94ADA7"
          onClick={handleHomeClick}
        >
          <IconHomeFilled />
        </Button>
        <Logout />
      </Group>
    </Group>
  );
}
