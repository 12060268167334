import { useState } from "react";
import { Popover, Text, Button, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import logoCV from "../Vitale-carte.png";
import { useStore } from "../Store";
import {client} from "../api/axiosClient"

export function SearchPatientfromCV() {
  const [loading, setLoading] = useState(false);
  const [opened, { close, open }] = useDisclosure(false);
  const [Beneficiaires, setBeneficiaires] = useState([]);

  const {
    currentUser,
    setCurrentSessionInfo,
    setSelectedPatientInfo,
    setShowPatientCard,
    setShowEncouterObjects,
    setOrdonnanceData,
    setBiologieData,
    setRadiologieData,
    setDispositifMedicalData,
    setActiveEncounter,
    setMiddleActive,
    setMiddleActiveTab,
    setActive,
    setActiveTab,
    setAgendaOpened,
    setMailboxOpened,
  } = useStore();

  async function GetBeneficiaires() {
    try {
      setLoading(true);
      const response = await client.get(`api/olaqin-auth/`);
     if (response.status === 200) {
        setLoading(false);
        if (response.data.redirect_url){
          window.location.href = response.data.redirect_url;
        }
        else if (response.data.beneficiaires){
          const beneficiaires = response.data.beneficiaires;
          console.log(beneficiaires);
          if (beneficiaires.length > 1) {
            setBeneficiaires(beneficiaires);
            open()
          } else if (beneficiaires.length === 1) {
            setBeneficiaires([])
            getPatient(beneficiaires[0].patient);
          } else {
            throw new Error("Ereur lecture Carte Vitale");
          }
        }
      }
       else {
        setLoading(false);
        throw new Error("Ereur lecture Carte Vitale");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  // Quel est l'ordonnance de medicaments en cours et l'afficher
  async function GetOrdonnanceMedicaments(subject, encounter) {
    try {
      const response = await client.get(
        `api/medication-requests/?practitioner=${currentUser.id}&subject=${subject}&encounter=${encounter}`
      );
      if (response.status === 200) {
        // console.log(response.data);
        return setOrdonnanceData(response.data.results);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setOrdonnanceData([]);
      }
      console.error("Failed to fetch Ordonnance:", error);
    } finally {
    }
  }

  async function GetOrdonnanceBiologies(subject, encounter) {
    try {
      const response = await client.get(
        `api/service-request/?practitioner=${currentUser.id}&subject=${subject}&encounter=${encounter}&categoryContent=Biologie`
      );

      if (response.status === 200) {
        // console.log(response.data);
        return setBiologieData(response.data.results);
      } else throw new Error("Network response was not ok");
    } catch (error) {
      console.error("Failed to fetch Biologie:", error);
      throw error;
    } finally {
    }
  }
  async function GetOrdonnanceRadiologies(subject, encounter) {
    try {
      const response = await client.get(
        `api/service-request/?practitioner=${currentUser.id}&subject=${subject}&encounter=${encounter}&categoryContent=Radiologie`
      );

      if (response.status === 200) {
        // console.log(response.data);
        return setRadiologieData(response.data.results);
      } else throw new Error("Network response was not ok");
    } catch (error) {
      console.error("Failed to fetch Biologie:", error);
      throw error;
    } finally {
    }
  }
  async function GetOrdonnanceDispositifsMedicaux(subject, encounter) {
    try {
      const response = await client.get(
        `api/service-request/?practitioner=${currentUser.id}&subject=${subject}&encounter=${encounter}&categoryContent=DispositifMedical`
      );

      if (response.status === 200) {
        // console.log(response.data);
        return setDispositifMedicalData(response.data.results);
      } else throw new Error("Network response was not ok");
    } catch (error) {
      console.error("Failed to fetch Biologie:", error);
      throw error;
    } finally {
    }
  }

  async function getPatient(patientId) {
    try {
      const response = await client.get(`api/patients/${patientId}/`);
      const patient = response.data;
      const formattedData = {
        value: `${patient.id}`,
        label: `${patient.NomNaissance} ${patient.ListePrenom}`,
        patient: patient,
      };

      selectCurrentPatient(formattedData);
      // console.log({'formattedData' : formattedData})
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch patient:", error);
    }
  }

  // Choisir un Patient
  const selectCurrentPatient = async (patient) => {
    setOrdonnanceData([]);
    setBiologieData([]);
    setSelectedPatientInfo(patient.patient);
    const currentSessionData = {
      practitioner: currentUser.id,
      subject: patient.patient.id,
      encounter: null,
    };
    setCurrentSessionInfo(currentSessionData);
    setShowPatientCard(true);
    setAgendaOpened(false);
    setMailboxOpened(false);
    // console.log(patient.patient);
    // console.log(currentSessionInfo);
    try {
      // Vérifiez s'il y a une consultation active pour ce patient et ce praticien
      const encounterResponse = await client.get(
        `api/encounters/?practitioner=${currentUser.id}&subject=${patient.patient.id}`
      );

      if (encounterResponse.status === 200) {
        const activeEncounterData = encounterResponse.data.results.find(
          (encounterData) => encounterData.status === "active"
        );
        if (activeEncounterData) {
          console.log("Consultation active en cours");
          const currentSessionData = {
            practitioner: currentUser.id,
            subject: patient.patient.id,
            encounter: activeEncounterData.id,
          };
          setCurrentSessionInfo(currentSessionData);
          setShowEncouterObjects(true);
          setActiveEncounter(true);
          setActive("medicaments");
          setActiveTab("medicaments");
          setMiddleActive("Historique des Consultations");
          setMiddleActiveTab("Historique des Consultations");
          GetOrdonnanceMedicaments(patient.patient.id, activeEncounterData.id);
          GetOrdonnanceBiologies(patient.patient.id, activeEncounterData.id);
          GetOrdonnanceRadiologies(patient.patient.id, activeEncounterData.id);
          GetOrdonnanceDispositifsMedicaux(
            patient.patient.id,
            activeEncounterData.id
          );
        } else {
          setShowEncouterObjects(false);
          setActiveEncounter(false);
          const currenSessionResponse = await client.get(
            `api/current-session/?practitioner=${currentUser.id}`
          );
          if (currenSessionResponse.status === 200) {
            const currentSessionData = {
              practitioner: currentUser.id,
              subject: patient.patient.id,
            };
            const currenSessionUpdateResponse = await client.put(
              `api/current-session/${currenSessionResponse.data.results.id}/`,
              currentSessionData
            );
          }
        }
      }
    } catch (error) {
      console.error(
        "Failed to fetch encounters or update current session:",
        error
      );
    }
  };
  return (
    <>
    
      {/* //  <Modal className="CVModal" opened={true} onClose={close} withCloseButton={false} overlayProps={{zIndex:4000}}> */}
      <Popover width={500} position="bottom-start" withArrow shadow="md" radius="12px"  opened={opened}>
         <Popover.Dropdown style={{ zIndex: '4000' }}>
       <Stack gap="xs">
         {Beneficiaires?.map?.((patient) => (
           <Button
           className="connectedButton"
             variant="filled"
             radius="12px"
             padding="xs"
             color="#94ADA7"
             withBorder={true}
             onClick={() => {getPatient(patient.patient); setBeneficiaires([]); close()}}
             key={patient.id}
           >
             {patient.nom} {patient.prenom} ({patient.dateNaissanceStr}
             ) - {patient.qualite}
           </Button>
         ))}
       </Stack>
       </Popover.Dropdown>
       <Popover.Target>
       <Button
       className="connectedButton"
        variant="filled"
        radius="12px"
        px="xs"
        color="#94ADA7"
        withBorder={true}
        onClick={() =>GetBeneficiaires()}
        loading={loading}
        disabled={loading}
      >
        <img src={logoCV} className="App-logo" width="35" />
      </Button>
      </Popover.Target>
     
      
    </Popover> 
   
     
      
     
 
    </>
  );
}
