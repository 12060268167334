import React, { useState, useEffect } from 'react';
import { Loader, Group, Text, Image, Button,Tooltip } from "@mantine/core";
import { IconCheck, IconCopyPlusFilled, IconPillFilled, IconPlus } from "@tabler/icons-react";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import {client} from "../api/axiosClient"


export const RenewMedication = ({ encounterId }) => {
  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const ordonnanceData = useStore((state) => state.ordonnanceData);
  const setOrdonnanceData = useStore((state) => state.setOrdonnanceData);
  const setSelectedMedicamentDetail = useStore(
    (state) => state.setSelectedMedicamentDetail
  );
  const { setActive, setActiveTab } = useStore();


  const [loading, setLoading] = useState(false);

  const addMedicament = async (value) => {
    setLoading(true);
    const medicamentData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      medoc_id: value.medoc_id, 
      nom_commercial: value.nom_commercial,
      dci: value.dci,
      dosage: value.dosage,
      forme: value.forme,
      voie: value.voie,
      commercialisation: value.commercialisation,
      ciscode: value.ciscode,
      matin: value.matin,
      midi: value.midi,
      soir: value.soir,
      coucher: value.coucher,
      periode: value.periode,
      nombre_periode: value.nombre_periode,
      commentaire:value.commentaire,
      tttAld: value.tttAld,
      tttFond: value.tttFond,
      nsCIF: value.nsCIF,
      nsEFG: value.nsEFG,
      nsMTE: value.nsMTE,
      rcp: value.rcp,
    };

    try {
      if (Array.isArray(ordonnanceData)) {
        const existingIndex = ordonnanceData.findIndex(
          (item) => item.medoc_id === value.medoc_id
        );

        if (existingIndex !== -1) {
            const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                {/* <Image
                  fit="contain"
                  src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                  alt="iAvicenne Logo"
                  width={15}
                  height={15}
                  style={{ margin: "0", display: "block" }}
                /> */}
                <Text fw={700} size="xs">{value.nom_commercial} déjà Prescrit </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,
          });
        } else {
          const response = await client.post(
            "api/medication-requests/",
            medicamentData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          if (response.status === 201) {
            // Mettre à jour l'état local avec l'ID retourné par l'API
            ordonnanceData.push({ ...medicamentData, id: response.data.id });
            setOrdonnanceData(ordonnanceData);
            console.log("Ordonnance mise à jour");
    
           
        }
      
      };
    }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renouvelerOrdo = async () => {
    setLoading(true);
    try {
      const response = await client.get(`api/encounters/${encounterId}/`);

      if (response.status === 200) {
        const ordonnance = response.data.encounterData.ordonnanceMedicaments;
        for (const medicament of ordonnance || []) {
            console.log(medicament)
          await addMedicament(medicament);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
    label={
    
        <Text size="xs">
         Renouveler l'ordonnance
        </Text>
      
    }
    color="#94ADA7"
    withArrow
    multiline
  
    position="top"
    radius="12px"
    arrowOffset={25}
    arrowSize={8}
    transitionProps={{ transition: "pop", duration: 500 }}
    openDelay={1000}
  >
    <Button 
    className='renouvelerButton'
      color="#94ADA7"
      radius="12px"
      size="xs"
      onClick={() => {
       
        renouvelerOrdo();
        setActive("medicaments")
        setActiveTab("medicaments")
      }} 
      loading={loading} 
      disabled={loading}
    >
       <IconPlus size={16}/>
       <IconPillFilled size={18}/>
    
    </Button>
    </Tooltip>
   
 
  );
};

export const RenewtttFond = () => {
  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const ordonnanceData = useStore((state) => state.ordonnanceData);
  const setOrdonnanceData = useStore((state) => state.setOrdonnanceData);
  const { setActive, setActiveTab } = useStore();
  const [loading, setLoading] = useState(false);

  

  const addMedicament = async (value) => {
    setLoading(true);
    const medicamentData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      medoc_id: value.medoc_id, 
      nom_commercial: value.nom_commercial,
      dci: value.dci,
      dosage: value.dosage,
      forme: value.forme,
      voie: value.voie,
      commercialisation: value.commercialisation,
      ciscode: value.ciscode,
      matin: value.matin,
      midi: value.midi,
      soir: value.soir,
      coucher: value.coucher,
      periode: value.periode,
      nombre_periode: value.nombre_periode,
      commentaire:value.commentaire,
      tttAld: value.tttAld,
      tttFond: value.tttFond,
      nsCIF: value.nsCIF,
      nsEFG: value.nsEFG,
      nsMTE: value.nsMTE,
      rcp: value.rcp,
    };

    try {
      if (Array.isArray(ordonnanceData)) {
        const existingIndex = ordonnanceData.findIndex(
          (item) => item.medoc_id === value.medoc_id
        );

        if (existingIndex !== -1) {
            const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                {/* <Image
                  fit="contain"
                  src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                  alt="iAvicenne Logo"
                  width={15}
                  height={15}
                  style={{ margin: "0", display: "block" }}
                /> */}
                <Text fw={700} size="xs">{value.nom_commercial} déjà Prescrit </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,
          });
        } else {
          const response = await client.post(
            "api/medication-requests/",
            medicamentData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          if (response.status === 201) {
            // Mettre à jour l'état local avec l'ID retourné par l'API
            ordonnanceData.push({ ...medicamentData, id: response.data.id });
            setOrdonnanceData(ordonnanceData);
            console.log("Ordonnance mise à jour");
    
           
        }
      
      };
    }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renouvelerOrdo = async () => {
    setLoading(true);
    try {
      const response = await client.get(`api/patients/${selectedPatientInfo.id}/`);

      if (response.status === 200) {
        const ordonnance = response.data.MedicationStatement;
        console.log(response.data)
        for (const medicament of ordonnance || []) {
           
          await addMedicament(medicament);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
    label={
    
        <Text size="xs">
         Renouveler l'ordonnance
        </Text>
      
    }
    color="#94ADA7"
    withArrow
    multiline
  
    position="top"
    radius="12px"
    arrowOffset={25}
    arrowSize={8}
    transitionProps={{ transition: "pop", duration: 500 }}
    openDelay={1000}
  >
    <Button 
    className='renouvelerButton'
      color="#94ADA7"
      radius="12px"
      size="xs"
      onClick={() => {
       
        renouvelerOrdo();
        setActive("medicaments")
        setActiveTab("medicaments")
      }} 
      loading={loading} 
      disabled={loading}
    >
       <IconPlus size={16}/>
       <IconPillFilled size={18}/>
    
    </Button>
    </Tooltip>
   
 
  );
};


