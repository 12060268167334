import { useForm } from "@mantine/form";
import {
  TextInput,
  Textarea,
  Select,
  Checkbox,
  NumberInput,
  Button,
  Group,
  Fieldset,
  Card,
  Stack,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconMail, IconPhone } from "@tabler/icons-react";
import { useStore } from "../Store";
import { useEffect } from "react";
import {client} from "../api/axiosClient"

export const PatientForm = () => {
  const {
    selectedPatientInfo,
    setSelectedPatientInfo,
  } = useStore();

  const birthDateString = selectedPatientInfo?.birthDate;
  let birthDate = "";

  if (birthDateString) {
    const [year, month, day] = birthDateString.split('-').map(Number);
    birthDate = new Date(Date.UTC(year, month - 1, day)); // Months are 0-indexed
  }



  const form = useForm({
    initialValues: {
      NomNaissance: selectedPatientInfo?.NomNaissance || "",
      UsagePrenom: selectedPatientInfo?.UsagePrenom || "",
      birthDate: birthDate || "",
      gender: selectedPatientInfo?.gender || "",
      INS : selectedPatientInfo?.INS || "",
      NumIdentifiant: selectedPatientInfo?.NumIdentifiant || "",
      Cle: selectedPatientInfo?.Cle,
      OID: selectedPatientInfo?.OID,
      Email: selectedPatientInfo?.telecom?.Email || "",
      Portable: selectedPatientInfo?.telecom?.Portable || "",
      Fixe: selectedPatientInfo?.telecom?.Fixe || "",
      Adresse: selectedPatientInfo?.adress?.Adresse || "",
      CodePostale: selectedPatientInfo?.adress?.CodePostale  || "",
      Ville: selectedPatientInfo?.adress?.Ville  || "",
      commentaire: selectedPatientInfo?.commentaire || "",
      ListePrenom: selectedPatientInfo?.ListePrenom || "",
      maritalStatus: selectedPatientInfo?.NomNaissance || "",
      LieuNaissance: selectedPatientInfo?.LieuNaissance || "",
      contact: selectedPatientInfo?.NomNaissance || "",
      generalPractitioner: selectedPatientInfo?.generalPractitioner || "",
      multipleBirthBoolean: selectedPatientInfo?.multipleBirthBoolean,
      deceasedBoolean: selectedPatientInfo?.deceasedBoolean,
    },

    // validate: {
    //   NumIdentifiant: (value) =>
    //     value === "" || undefined || (value?.length === 13 && /^\d+$/.test(value))
    //       ? null
    //       : "Le NIR doit contenir exactement 13 chiffres",
    //   Cle: (value) =>
    //     value === "" || undefined || (value?.length === 2 && /^\d+$/.test(value))
    //       ? null
    //       : "La Clé NIR doit contenir exactement 2 chiffres",
    // },
  });

  useEffect(() => {
    const birthDateString = selectedPatientInfo?.birthDate;
    let birthDate = "";

    if (birthDateString) {
      const [year, month, day] = birthDateString.split('-').map(Number);
      birthDate = new Date(Date.UTC(year, month - 1, day));
    }

    form.setValues({
      NomNaissance: selectedPatientInfo?.NomNaissance || "",
      UsagePrenom: selectedPatientInfo?.UsagePrenom || "",
      birthDate: birthDate || "",
      gender: selectedPatientInfo?.gender || "",
      NumIdentifiant: selectedPatientInfo?.NumIdentifiant || "",
      INS : selectedPatientInfo?.INS || "",
      Cle: selectedPatientInfo?.Cle,
      OID: selectedPatientInfo?.OID,
      Email: selectedPatientInfo?.telecom?.Email || "",
      Portable: selectedPatientInfo?.telecom?.Portable || "",
      Fixe: selectedPatientInfo?.telecom?.Fixe || "",
      Adresse: selectedPatientInfo?.adress?.Adresse || "",
      CodePostale: selectedPatientInfo?.adress?.CodePostale || "",
      Ville: selectedPatientInfo?.adress?.Ville || "",
      commentaire: selectedPatientInfo?.commentaire || "",
      ListePrenom: selectedPatientInfo?.ListePrenom || "",
      maritalStatus: selectedPatientInfo?.maritalStatus || "",
      LieuNaissance: selectedPatientInfo?.LieuNaissance || "",
      contact: selectedPatientInfo?.contact || "",
      generalPractitioner: selectedPatientInfo?.generalPractitioner || "",
      multipleBirthBoolean: selectedPatientInfo?.multipleBirthBoolean,
      deceasedBoolean: selectedPatientInfo?.deceasedBoolean,
    });
  }, [selectedPatientInfo]);
  
  const handleSubmit = async (values) => {
    try {

      const PatientFormData = {
        NomNaissance: values.NomNaissance || "",
        UsagePrenom: values.UsagePrenom || "",
        birthDate: values.birthDate
            ? new Date(values.birthDate).toISOString().split('T')[0]
            : null,
        gender: values.gender || "",
        NumIdentifiant: values.NumIdentifiant
            ? parseInt(values.NumIdentifiant, 10)
            : null,
        Cle: values.Cle ? parseInt(values.Cle, 10) : null,
        OID: values.OID || "",
        telecom: {
            Email: values.Email || "",
            Portable: values.Portable || "",
            Fixe: values.Fixe || "",
        },
        address: {
            Adresse: values.Adresse || "",
            CodePostale: values.CodePostale || "",
            Ville: values.Ville || "",
        },
        commentaire: values.commentaire || "",
        ListePrenom: values.ListePrenom || "",
        maritalStatus: values.maritalStatus || "",
        LieuNaissance: values.LieuNaissance
            ? parseInt(values.LieuNaissance, 10)
            : null,
        contact: [values.contact || null],
        generalPractitioner: values.generalPractitioner || "",
        multipleBirthBoolean: values.multipleBirthBoolean,
        deceasedBoolean: values.deceasedBoolean,
    };
    
      console.log(PatientFormData)

      
      const response = await client.patch(
        `api/patients/${selectedPatientInfo.id}/`,
        PatientFormData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        console.log(response.data);
        console.log("Données Patient Mises à jour");
        return true; // Indiquer que la sauvegarde a réussi
      } else {
        console.error("Erreur lors de la sauvegarde du formulaire données patient:", response);
        return false; // Indiquer que la sauvegarde a échoué
      }
    } catch (error) {
      console.log(error);
      return false; // Indiquer que la sauvegarde a échoué
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Card radius="12px" withBorder={true}>
        <Stack>
          <Fieldset legend="Identité du Patient" radius="12px" ta="left">
            <Group grow mb={5}>
              <TextInput
                variant="filled"
                radius="12px"
                label="Nom"
                placeholder="Nom de naissance"
                {...form.getInputProps("NomNaissance")}
                required
              />

              <TextInput
                variant="filled"
                radius="12px"
                label="Prénom"
                placeholder="Prénom d'usage"
                {...form.getInputProps("UsagePrenom")}
                required
              />
              <DateInput
                variant="filled"
                radius="12px"
                label="Naissance"
                valueFormat="DD/MM/YYYY"
                placeholder="Date de Naissance"
                {...form.getInputProps("birthDate")}
                required
              />
            </Group>
            <Group grow mb={5}>
            <Select
              variant="filled"
              radius="12px"
              label="Genre"
              placeholder="Choisissez le genre"
              data={["Masculin", "Féminin"]}
              {...form.getInputProps("gender")}
              required
            />
            <NumberInput
                variant="filled"
                radius="12px"
                label="INS"
                placeholder="Identifiant National de Santé"
                minLength={13}
                maxLength={13}
                {...form.getInputProps("INS")}
                hideControls
              />
              <NumberInput
                variant="filled"
                radius="12px"
                label="Lieu de Naissance"
                placeholder="Code du Lieu de naissance"
                {...form.getInputProps("LieuNaissance")}
                hideControls
              />
              </Group>
          </Fieldset>

          <Fieldset legend="Sécurité Sociale" radius="12px" ta="left">
            <Group grow>
              <NumberInput
                variant="filled"
                radius="12px"
                label="NIR"
                placeholder="NIR"
                minLength={13}
                maxLength={13}
                {...form.getInputProps("NumIdentifiant")}
                hideControls
              />
              <NumberInput
                variant="filled"
                radius="12px"
                label="Clé NIR"
                placeholder="Clé NIR"
                {...form.getInputProps("Cle")}
                minLength={2}
                maxLength={2}
                hideControls
              />
              <TextInput
                variant="filled"
                radius="12px"
                label="OID"
                placeholder="OID"
                {...form.getInputProps("OID")}
              />
            </Group>
          </Fieldset>
          <Fieldset legend="Coordonnées du Patient" radius="12px" ta="left">
            <Group grow mb={5}>
              <TextInput
                variant="filled"
                radius="12px"
                label="Email"
                placeholder="Email"
                leftSection={<IconMail size={16} />}
                {...form.getInputProps("Email")}
              />
              <TextInput
                variant="filled"
                radius="12px"
                label="Portable"
                placeholder="Téléphone Portable"
                leftSection={<IconPhone size={16} />}
                {...form.getInputProps("Portable")}
              />
              <TextInput
                variant="filled"
                radius="12px"
                label="Fixe"
                placeholder="Téléphone Fixe"
                leftSection={<IconPhone size={16} />}
                {...form.getInputProps("Fixe")}
              />
            </Group>

            <Group grow>
              <TextInput
                variant="filled"
                radius="12px"
                label="Adresse"
                placeholder="N°, Rue, ..."
                {...form.getInputProps("Adresse")}
              />
              <TextInput
                variant="filled"
                radius="12px"
                label="Code postale"
                placeholder="Code Postale"
                {...form.getInputProps("CodePostale")}
              />

              <TextInput
                variant="filled"
                radius="12px"
                label="Ville"
                placeholder="Ville"
                {...form.getInputProps("Ville")}
              />
            </Group>
          </Fieldset>
          <Fieldset legend="Commentaire" radius="12px" ta="left">
            <Textarea
              variant="filled"
              radius="12px"
              placeholder="Commentaire"
              {...form.getInputProps("commentaire")}
            />
          </Fieldset>
          <Fieldset legend="Autres" radius="12px" ta="left">
            <Group grow mb={5}>
              <TextInput
                variant="filled"
                radius="12px"
                label="Liste des prénoms"
                placeholder="Prénoms"
                {...form.getInputProps("ListePrenom")}
              />

              <TextInput
                variant="filled"
                radius="12px"
                label="Statut marital"
                placeholder="Statut marital"
                {...form.getInputProps("maritalStatus")}
              />

            </Group>

            <Group grow mb={15}>
              <TextInput
                variant="filled"
                radius="12px"
                label="Contact d'urgence"
                placeholder="Contact d'urgence"
                {...form.getInputProps("contact")}
              />

              <TextInput
                variant="filled"
                radius="12px"
                label="Médecin traitant"
                placeholder="Nom du médecin traitant"
                {...form.getInputProps("generalPractitioner")}
              />
            </Group>
            <Group>
              <Checkbox
                variant="filled"
                radius="12px"
                label="Naissance multiple"
                {...form.getInputProps("multipleBirthBoolean", {
                  type: "checkbox",
                })}
              />
              <Checkbox
                variant="filled"
                radius="12px"
                label="Décédé"
                {...form.getInputProps("deceasedBoolean", { type: "checkbox" })}
              />
            </Group>
          </Fieldset>

          <Group position="right" mt="md" grow>
            <Button variant="light" color="#94ADA7" radius="12px" type="submit">
              Valider
            </Button>
          </Group>
        </Stack>
      </Card>
    </form>
  );
};
