import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from   "@fullcalendar/multimonth";
import listPlugin from "@fullcalendar/list";
import frLocale from "@fullcalendar/core/locales/fr";
import { Popover, TextInput, Stack, Button, Text, Transition } from "@mantine/core";
import SearchPatientEvent from "./SearchPatientEvent";
import {client} from "../api/axiosClient"


export function Calendar({ currentUser }) {
  const [calendarApi, setCalendarApi] = useState(null);
  const [events, setEvents] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [createEventPopoverOpened, setCreateEventPopoverOpened] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [popoverOpened, setPopoverOpened] = useState(false);
  // const [initialEvents, setInitialEvents] = useState([]);


  useEffect(() => {
    if (currentUser) {
      // console.log("Fetching events for:", currentUser);
      fetchEvents();
      loadEventsFromLocalStorage()
     
    }
  }, [currentUser]);

  
    const fetchEvents = async () => {
      try {
        const response = await client.get(`api/appointments/?practitionner=${currentUser.id}`);
        // console.log({'reponse de du backend':response})
        if (response.status == 200) {
          const events = response.data.map((event) => ({
            id: event.id,
            title: event.title,
            start: event.start,
            end: event.end,
            allDay: event.allDay,
            extendedProps: event.extendedProps,
            patient: event.patient,
          }));
          // console.log({'const events':response})
          // setInitialEvents(events);
          saveEventsToLocalStorage(events);
        } else {
          console.error("Failed to fetch events", response);
        }
      } catch (error) {
        console.error("Failed to fetch events", error.response ? error.response.data : error.message);
      }
    };



  const saveEventsToLocalStorage = (events) => {
    localStorage.setItem("events", JSON.stringify(events));
  };

  const loadEventsFromLocalStorage = () => {
    const storedEvents = localStorage.getItem("events");
    if (storedEvents) {
      const parsedEvents = JSON.parse(storedEvents);
      // console.log("Loaded events from local storage:", parsedEvents);
      return parsedEvents;
    }
    return [];
  };

  const handleDateSelect = (selectInfo) => {
    setSelectedDate(selectInfo);
    setCreateEventPopoverOpened(true);
  };

  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;
    const start = event.start;
    const end = event.end;
    setSelectedEvent({
      id: event.id,
      title: event.title,
      timeText: `${start.toTimeString().split(" ")[0]} - ${end ? end.toTimeString().split(" ")[0] : "..."}`,
      NomNaissance: event.extendedProps?.selectedPatient?.NomNaissance ?? null,
      ListePrenom: event.extendedProps?.selectedPatient?.ListePrenom ?? null,
    });
    setPopoverOpened(true);
    setCreateEventPopoverOpened(false);
  };

  const handlePatientClick = (value) => {
    setSelectedPatient(value.patient);
  };

  const handleCreateEvent = async () => {
    if (!selectedPatient) {
      return;
    }

    const calendarApi = selectedDate.view.calendar;
    const newEvent = {
      practitioner: currentUser.id,
      patient: selectedPatient.id,
      calendarId: Math.floor(Math.random() * 10000),
      title: eventTitle,
      start: selectedDate.startStr,
      end: selectedDate.endStr,
      allDay: selectedDate.allDay,
      extendedProps: { selectedPatient },
    };
    try {
      const response = await client.post("api/appointments/", newEvent, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status == 201) {
        newEvent.id = response.data.id;
        calendarApi.unselect();
        calendarApi.addEvent({ ...newEvent, extendedProps: { selectedPatient } });
        setCreateEventPopoverOpened(false);
        setEventTitle("");
        setSelectedPatient(null);
        const updatedEvents = [...events, newEvent];
        setEvents(updatedEvents);
        saveEventsToLocalStorage(updatedEvents);
      } else {
        console.error("Failed to create event:", response);
      }
    } catch (error) {
      console.error("Failed to create event:", error);
    }
  };

  const handleDeleteEvent = async () => {
    if (!calendarApi || !selectedEvent) return;

    const eventId = selectedEvent.id;
    try {
      const response = await client.delete(`api/appointments/${eventId}/`, {
        headers: { "Content-Type": "application/json" },
      });


      if (response.status === 204) {
        const event = calendarApi.getEventById(eventId);
        if (event) event.remove();

        // Remove the event from state and localStorage
        const updatedEvents = events.filter(event => event.id !== eventId);
        setEvents(updatedEvents);
        saveEventsToLocalStorage(updatedEvents);

        setPopoverOpened(false);
      } else {
        console.error(`Failed to delete event: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to delete event:", error.response ? error.response.data : error.message);
    }
  };

  const renderEventContent = (eventInfo) => {
    const { extendedProps = {} } = eventInfo.event || {};
    const { selectedPatient = {} } = extendedProps;
    const { NomNaissance, ListePrenom } = selectedPatient;
    
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <strong>
          <i>
            &nbsp;&nbsp;{NomNaissance} {ListePrenom}
          </i>
        </strong>
      </>
    );
  };

  return (
    <div>
     
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, multiMonthPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "multiMonthYear dayGridMonth timeGridWeek timeGridDay listWeek",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        navLinks={true}
        // weekends={weekendsVisible}
        select={handleDateSelect}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
        eventsSet={(events) => setEvents(events)}
        initialEvents={loadEventsFromLocalStorage()}
        locale={frLocale}
        height={800}
        aspectRatio={2}
        slotMinTime={"06:00"}
        slotMaxTime={"22:00"}
        slotDuration={"00:15"}
        expandRows={true}
        ref={(calendar) => calendar && setCalendarApi(calendar.getApi())}
      />
      
      <Popover
        trapFocus
        position="bottom"
        withArrow
        shadow="md"
        opened={createEventPopoverOpened}
        onClose={() => setCreateEventPopoverOpened(false)}
      >
        <Popover.Dropdown className="CreateEventPop">
          <Stack align="flex-start" spacing="xl" h={300}>
          <Button variant="subtle" color="gray"
            onClick={() => setCreateEventPopoverOpened(false)}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            X
          </Button>
            <TextInput
              value={eventTitle}
              onChange={(e) => setEventTitle(e.currentTarget.value)}
              placeholder="Motif de la consultation"
            />
            {selectedPatient ? (
              <Button variant="light" color="#94ADA7">
                {selectedPatient.NomNaissance} {selectedPatient.ListePrenom}
              </Button>
            ) : (
              <SearchPatientEvent handlePatientClick={handlePatientClick} />
            )}
            <Button
              onClick={handleCreateEvent}
              disabled={!selectedPatient}
              variant="filled"
              color="#94ADA7"
            >
             Créer le Rendez-Vous
            </Button>
          </Stack>
        </Popover.Dropdown>
      </Popover>

      <Popover
        width={300}
        trapFocus
        position="bottom"
        withArrow
        shadow="md"
        opened={popoverOpened}
        onClose={() => setPopoverOpened(false)}
      >
        <Popover.Dropdown className="DeleteEventPop">
          <Stack align="flex-start" spacing="xl" h={300}>
                <Button variant="subtle" color="gray"
            onClick={() => setPopoverOpened(false)}
            style={{ position: "absolute", top: 10, right: 10 }}
          >
            X
          </Button>
            {selectedEvent && (
              <div>
                <Text><strong>{selectedEvent.title}</strong></Text>
                <Text>{selectedEvent.timeText}</Text>
                {selectedEvent.NomNaissance && selectedEvent.ListePrenom ? (
                  <Text>
                    <strong>
                      {selectedEvent.NomNaissance} {selectedEvent.ListePrenom}
                    </strong>
                    <br />
                  </Text>
                ) : (
                  <Text>
                    <strong>{selectedEvent.title}</strong> 
                  </Text>
                )}
              </div>
            )}
            <Button onClick={handleDeleteEvent} color="red">
              Supprimer le Rendez-vous
            </Button>
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
}

