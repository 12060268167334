import React, { useEffect, useState, useCallback } from "react";
import { Select, Loader } from "@mantine/core";
import { IconSearch, IconUserSearch } from "@tabler/icons-react";
import { useStore } from "../Store";
import {client} from "../api/axiosClient"

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

async function getPatient(query = "") {
  const response = await client.get(`api/patients/search/?name=${query}`);
 
  if (response.status===200){console.log(response)}
  if (response.status !== 200) throw new Error("Network response was not ok");
  return response.data;
}

export function SearchPatient() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setquery] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);


  const {
    currentUser,
    currentSessionInfo,
    setCurrentSessionInfo,
    setSelectedPatientInfo,
    setShowPatientCard,
    setShowEncouterObjects,
    setOrdonnanceData,
    setBiologieData,
    setRadiologieData,
    setDispositifMedicalData,
    setActiveEncounter,
    setOrdonnanceLibreData,
    setMiddleActive,
    setMiddleActiveTab,
    setActive,
    setActiveTab,
    setAgendaOpened,
    setMailboxOpened,
   
  } = useStore();

  // Quel est l'ordonnance de medicaments en cours et l'afficher
async function GetOrdonnanceMedicaments(subject, encounter) {
    try {
      const response = await client.get(
        `api/medication-requests/?limit=100&practitioner=${currentUser.id}&subject=${subject}&encounter=${encounter}`
      );
      if (response.status === 200) {
        // console.log(response.data);
        return setOrdonnanceData(response.data.results);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setOrdonnanceData([]);
      }
      console.error("Failed to fetch Ordonnance:", error);
    } finally {
    }
  }

  async function GetOrdonnanceBiologies(subject, encounter) {
    try {
      const response = await client.get(
        `api/service-request/?limit=100practitioner=${currentUser.id}&subject=${subject}&encounter=${encounter}&categoryContent=Biologie`
      );

      if (response.status === 200) {
        // console.log(response.data);
        return setBiologieData(response.data.results);
      } else throw new Error("Network response was not ok");
    } catch (error) {
      console.error("Failed to fetch Biologie:", error);
      throw error;
    } finally {
    }
  }
  async function GetOrdonnanceRadiologies(subject, encounter) {
    try {
      const response = await client.get(
        `api/service-request/?limit=100practitioner=${currentUser.id}&subject=${subject}&encounter=${encounter}&categoryContent=Radiologie`
      );

      if (response.status === 200) {
        // console.log(response.data);
        return setRadiologieData(response.data.results);
      } else throw new Error("Network response was not ok");
    } catch (error) {
      console.error("Failed to fetch Biologie:", error);
      throw error;
    } finally {
    }
  }
  async function GetOrdonnanceDispositifsMedicaux(subject, encounter) {
    try {
      const response = await client.get(
        `api/service-request/?limit=100practitioner=${currentUser.id}&subject=${subject}&encounter=${encounter}&categoryContent=DispositifMedical`
      );

      if (response.status === 200) {
        // console.log(response.data);
        return setDispositifMedicalData(response.data.results);
      } else throw new Error("Network response was not ok");
    } catch (error) {
      console.error("Failed to fetch Biologie:", error);
      throw error;
    } finally {
    }
  }

  const debouncedGetPatient = useCallback(
    debounce(async (query) => {
      try {
        const patients = await getPatient(query);
        const formattedData = patients.patients.map((patient) => ({
          value: `${patient.id}`,
          label: `${patient.NomNaissance} ${patient.UsagePrenom}`,
          patient: patient,
        }));
        setData(formattedData);
        // console.log({'formattedData' : formattedData})
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch patient:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (query) {
      debouncedGetPatient(query);
    } else {
      setData([]);
    }
  }, [query, debouncedGetPatient]);

  // Choisir un Patient
  const selectCurrentPatient = async (patient) => {
    setOrdonnanceData([]);
    setBiologieData([]);
    setSelectedPatientInfo(patient.patient);
    const currentSessionData = {
      practitioner: currentUser.id,
      subject: patient.patient.id,
      encounter:null
    };
    setCurrentSessionInfo(currentSessionData);
    setShowPatientCard(true);
    setAgendaOpened(false);
    setMailboxOpened(false);
    // console.log(patient.patient);
    // console.log(currentSessionInfo);
    try {
      // Vérifiez s'il y a une consultation active pour ce patient et ce praticien
      const encounterResponse = await client.get(
        `api/encounters/?practitioner=${currentUser.id}&subject=${patient.patient.id}`
      );

      if (encounterResponse.status === 200) {
        const activeEncounterData = encounterResponse.data.results.find(
          (encounterData) => encounterData.status === "active"
        );
        if (activeEncounterData) {
          console.log("Consultation active en cours");
          const currentSessionData = {
            practitioner: currentUser.id,
            subject: patient.patient.id,
            encounter:activeEncounterData.id
          };
          setCurrentSessionInfo(currentSessionData);
          setShowEncouterObjects(true);
          setActiveEncounter(true);
          setActive("medicaments");
          setActiveTab("medicaments");
          setMiddleActive("Historique des Consultations")
          setMiddleActiveTab("Historique des Consultations")
          GetOrdonnanceMedicaments(patient.patient.id, activeEncounterData.id);
          GetOrdonnanceBiologies(patient.patient.id, activeEncounterData.id);
          GetOrdonnanceRadiologies(patient.patient.id, activeEncounterData.id);
          GetOrdonnanceDispositifsMedicaux(patient.patient.id, activeEncounterData.id);
        } else {
          setShowEncouterObjects(false);
          setActiveEncounter(false);
          setOrdonnanceLibreData([])
          const currenSessionResponse = await client.get(
            `api/current-session/?practitioner=${currentUser.id}`
          );
          if(currenSessionResponse.status === 200){
            const currentSessionData ={
              practitioner:currentUser.id,
              subject:patient.patient.id
            }
            console.log(currenSessionResponse)
            const currenSessionUpdateResponse = await client.patch(
              `api/current-session/${currenSessionResponse.data.results.id}/`,
              currentSessionData
            );
          }
        }
      }
    } catch (error) {
      console.error(
        "Failed to fetch encounters or update current session:",
        error
      );
    }
  };
  return (
    <Select
    className="patientSelect"
    classNames={{
      input: 'patientSelect-input',
    }}
    
      searchable
      searchValue={query}
      onSearchChange={setquery}
      value={selectedValue}
      placeholder="Rechercher un Patient"
      data={data}
      loading={loading && <Loader size="sm" />}
    
      rightSection={<IconUserSearch className="patientSearchIcon" color="#fff" size="25px" style={{ translate:-15 }} />}
      maxDropdownHeight={200}
      onChange={(id, value) => {
        if (value) {
          selectCurrentPatient(value);
          setSelectedValue(null);
          setquery("");
          setData([]);
        }
      }}
      comboboxProps={{
        transitionProps: { transition: "pop", duration: 200 },
        shadow: "md",
        width: "300px",
        radius:"md",
      }}
    
      style={{ width: "300px",shadow: "md"}}
    />
  );
}


