import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Image,
  Grid,
  Switch,
  Stack,
  Group,
  Text,
  Card,
  Title,
  SegmentedControl,
  NumberInput,
  rem,
  Textarea,
  Fieldset,
  Tabs,
  ScrollArea,
  Collapse,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconBiohazardFilled,
  IconCheck,
  IconHospital,
  IconTarget,
  IconTargetOff,
  IconX,
} from "@tabler/icons-react";
import { useStore } from "../Store";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";
import {client} from "../api/axiosClient"

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function ShowMedicament() {
  const {
    ordonnanceData,
    setOrdonnanceData,
    posologie,
    setPosologie,
    selectedMedicamentDetail,
    setSelectedMedicamentDetail,
    selectedPatientInfo,
    setSelectedPatientInfo,
  } = useStore();

  const [localMedicament, setLocalMedicament] = useState(
    selectedMedicamentDetail
  );
  const [opened, { toggle }] = useDisclosure(false);

  // const getPosologie = async (updatedMedicament) => {
  //   // Vérifier si le médicament sélectionné contient déjà une clé 'Posologie'
  //   if (!selectedMedicamentDetail.rcp || typeof selectedMedicamentDetail?.rcp !== "object") {
  //     console.log(selectedMedicamentDetail)
  //   try {
  //     const response = await client.get(
  //       `api/rcp/?ciscode=${selectedMedicamentDetail.ciscode}`
  //     );
  //     if (response.status === 200) {
  //       console.log({"rcpResponse":response.data});
  //       const posologieData = response.data;
  //       // setPosologie(posologieData);

  //       // Mettre à jour selectedMedicamentDetail avec la nouvelle Posologie
  //       const updatedMedicamentDetail = {
  //         ...updatedMedicament,
  //         rcp: posologieData,
  //       };
  //       setSelectedMedicamentDetail(updatedMedicamentDetail);
  //       debouncedUpdateDatabase(updatedMedicamentDetail);

  //       // Mettre à jour ordonnanceData
  //       const updatedOrdonnanceData = ordonnanceData.map((medicament) =>
  //         medicament.id === updatedMedicament.id
  //           ? updatedMedicamentDetail
  //           : medicament
  //       );
  //       setOrdonnanceData(updatedOrdonnanceData);
  //     } else {
  //       console.log("error, couldn't get posologie");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //     }
  // };

  useEffect(() => {
    setLocalMedicament(selectedMedicamentDetail);
    console.log(selectedMedicamentDetail)
    // setPosologie([]);
    // getPosologie(selectedMedicamentDetail);
  }, [selectedMedicamentDetail]);

  useEffect(() => {
    if (selectedPatientInfo?.MedicationStatement) {
      const medicamentInMedicationStatement =
        selectedPatientInfo.MedicationStatement.find(
          (medication) =>
            medication.medoc_id === selectedMedicamentDetail?.medoc_id
        );
  
      if (medicamentInMedicationStatement) {
        setLocalMedicament((prevState) => ({
          ...prevState,
          tttFond: medicamentInMedicationStatement.tttFond,
        }));
      }
    }
  }, [selectedPatientInfo, selectedMedicamentDetail]);

  const updateDatabase = async (updatedMedicament) => {
    try {
      console.log({ updatedMedicament: updatedMedicament });

      // Récupérer l'id du médicament dans la MedicationRequest
      const medicationRequest = ordonnanceData.find(
        (medication) => medication.medoc_id === updatedMedicament.medoc_id
      );

      if (medicationRequest) {
        const response = await client.patch(
          `api/medication-requests/${medicationRequest.id}/`,
          updatedMedicament
        );
        if (response.status === 200) {
          console.log("medication request updated");
        } else {
          console.log("error, couldn't update medication request");
        }
      } else {
        console.log("Médicament non trouvé dans la MedicationRequest");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedUpdateDatabase = useCallback(
    debounce(updateDatabase, 2000),
    []
  );

  const handleReset = () => {
    // Reset the localMedicament to its initial state (clearing inputs)
    const updatedMedicament={
      ...selectedMedicamentDetail,
      commentaire: "",
      matin: 0,
      midi: 0,
      soir: 0,
      coucher: 0,
      nombre_periode: 0,
      periode: "Jour",
      nsMTE: false,
      nsEFG: false,
      nsCIF: false,
      renouveler: 0,
    };
    setLocalMedicament(updatedMedicament);
    setSelectedMedicamentDetail(updatedMedicament);

    const updatedOrdonnanceData = ordonnanceData.map((medicament) =>
      medicament.id === updatedMedicament.id ? updatedMedicament : medicament
    );

    setOrdonnanceData(updatedOrdonnanceData);

    debouncedUpdateDatabase(updatedMedicament);
  };

  const updateMedicament = useCallback(
    (key, value) => {
      // Mise à jour du médicament avec la clé et la valeur passées
      const updatedMedicament = { ...localMedicament, [key]: value };

      // Logique pour mettre à jour le commentaire en fonction du switch ou input modifié
      let updatedCommentaire = updatedMedicament.commentaire || "";

      // Gérer les changements des switches
      if (key === "nsMTE") {
        updatedCommentaire = updatedCommentaire.replace(
          /Ne pas substituer \(MTE\)/g,
          ""
        );
        if (value) {
          updatedCommentaire += " Ne pas substituer (MTE)";
        }
      } else if (key === "nsEFG") {
        updatedCommentaire = updatedCommentaire.replace(
          /Ne pas substituer \(EFG\)/g,
          ""
        );
        if (value) {
          updatedCommentaire += " Ne pas substituer (EFG)";
        }
      } else if (key === "nsCIF") {
        updatedCommentaire = updatedCommentaire.replace(
          /Ne pas substituer \(CIF\)/g,
          ""
        );
        if (value) {
          updatedCommentaire += " Ne pas substituer (CIF)";
        }
      }

      // Gérer l'ajout ou la modification du renouvellement
      if (key === "renouveler") {
        updatedCommentaire = updatedCommentaire.replace(
          / ?à renouveler \d+ fois\./g,
          ""
        );
        if (value > 0) {
          updatedCommentaire += ` à renouveler ${value} fois.`;
        }
      }

      // Gérer les prises individuelles (matin, midi, soir, coucher)
      if (key === "matin") {
        // Supprimer l'ancienne information de "matin"
        updatedCommentaire = updatedCommentaire.replace(/ ?\d+ le Matin/g, "");
        // Ajouter la nouvelle information de "matin" si positive
        if (value > 0) {
          updatedCommentaire += ` ${value} le Matin`;
        }
      }

      if (key === "midi") {
        updatedCommentaire = updatedCommentaire.replace(/ ?\d+ le Midi/g, "");
        if (value > 0) {
          updatedCommentaire += ` ${value} le Midi`;
        }
      }

      if (key === "soir") {
        updatedCommentaire = updatedCommentaire.replace(/ ?\d+ le Soir/g, "");
        if (value > 0) {
          updatedCommentaire += ` ${value} le Soir`;
        }
      }

      if (key === "coucher") {
        updatedCommentaire = updatedCommentaire.replace(
          / ?\d+ au Coucher/g,
          ""
        );
        if (value > 0) {
          updatedCommentaire += ` ${value} au Coucher`;
        }
      }

      // Gérer la période du traitement
      if (key === "nombre_periode" || key === "periode") {
        updatedCommentaire = updatedCommentaire.replace(
          / ?Pendant \d+ (Jour|Semaine|Mois)s?/gi,
          ""
        );

        const nombrePeriode = updatedMedicament.nombre_periode || 0;
        const periode = updatedMedicament.periode || "Jour";
        // Corriger le singulier/pluriel

        if (nombrePeriode > 0) {
          updatedCommentaire += ` Pendant ${nombrePeriode} ${periode}`;
        }
      }

      // Nettoyer les espaces inutiles et mettre à jour le champ commentaire
      updatedMedicament.commentaire = updatedCommentaire.trim();

      setLocalMedicament(updatedMedicament);
      setSelectedMedicamentDetail(updatedMedicament);

      const updatedOrdonnanceData = ordonnanceData.map((medicament) =>
        medicament.id === updatedMedicament.id ? updatedMedicament : medicament
      );

      setOrdonnanceData(updatedOrdonnanceData);

      debouncedUpdateDatabase(updatedMedicament);
    },
    [
      localMedicament,
      ordonnanceData,
      setOrdonnanceData,
      setSelectedMedicamentDetail,
      debouncedUpdateDatabase,
    ]
  );

  // Correction 2: Simplification de la logique conditionnelle
const handleTttFondChange = async (updatedMedicament) => {
  try {
    const hasDuplicate = selectedPatientInfo?.MedicationStatement?.some(
      (medication) => medication.medoc_id === updatedMedicament.medoc_id
    );

    if (hasDuplicate) {
      notifications.show({
        // ... (le reste du code de notification reste inchangé)
      });
      return;
    }

    const updatedFields = {
      MedicationStatement: [
        ...(selectedPatientInfo?.MedicationStatement || []),
        { ...updatedMedicament, tttFond: true },
      ],
    };

    const response = await client.patch(
      `api/patients/${selectedPatientInfo?.id}/`,
      updatedFields
    );

    if (response.status === 200) {
      setSelectedPatientInfo(response.data);
    } else {
      console.log("Error updating patient information");
    }
  } catch (error) {
    console.log(error);
  }
};

  const renderSwitch = (key, label) => (
    <Switch
      checked={localMedicament[key]}
      onChange={(event) => updateMedicament(key, event.currentTarget.checked)}
      color="teal"
      onLabel={label}
      offLabel={label}
      size="lg"
      radius="12px"
      thumbIcon={
        localMedicament[key] ? (
          <IconCheck
            style={{ width: rem(12), height: rem(12) }}
            color="teal"
            stroke={3}
          />
        ) : (
          <IconX
            style={{ width: rem(12), height: rem(12) }}
            color="red"
            stroke={3}
          />
        )
      }
      style={{ marginTop: "0.9rem" }}
    />
  );

  const renderInput = (key, label, type = "text") => (
    <Textarea
      value={localMedicament[key] || ""}
      onChange={(event) => updateMedicament(key, event.currentTarget.value)}
      placeholder={label}
      variant="filled"
      size="xs"
      radius="12px"
      type={type}
    />
  );

  const renderNumberInput = (key, label) => (
    <NumberInput
      value={localMedicament[key] || 0}
      onChange={(value) => updateMedicament(key, value)}
      variant="filled"
      size="xs"
      radius="12px"
      description={label}
      style={{ maxWidth: "95" }}
      allowNegative={false}
      allowDecimal={true}
      decimalScale={2}
      stepHoldDelay={500}
      stepHoldInterval={100}
      step={1}
    />
  );

  const renderSegmentedControl = (key, label, options) => (
    <SegmentedControl
      data={options}
      value={localMedicament[key] || 0}
      onChange={(value) => updateMedicament(key, value)}
      color="#94ADA7"
      size="xs"
      
      radius="12px"
      transitionDuration={500}
      transitionTimingFunction="linear"
      withItemsBorders={false}
      style={{ marginTop: "0.9rem", minWidth: "140",padding:"var(--sc-padding-md)"}}
    />
  );

  const data = [
    {
      value: "indications",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconTarget size="16" />
          <Text size="xs">Indications & Posologie</Text>
        </Group>
      ),
    },
    {
      value: "contre indications",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconTargetOff size="16" />
          <Text size="xs">Contre-Indications</Text>
        </Group>
      ),
    },
    {
      value: "précautions",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconAlertCircle size="16" />
          <Text size="xs">Précautions d'Emploi</Text>
        </Group>
      ),
    },
    {
      value: "effets indésirables",
      label: (
        <Group style={{ gap: "0.5rem" }} justify="center">
          <IconBiohazardFilled size="16" />
          <Text size="xs">Effets Indésirables</Text>
        </Group>
      ),
    },
  ];

  const [active, setActive] = useState("indications");
  const handleDocumentsControlChange = (value) => {
    setActive(value);
  };

  return (
    <Stack>
      <Card radius="12px" shadow="lg" padding="lg" withBorder={true}>
        <Grid align="center">
          <Grid.Col span={1}>
            <Image
              fit="contain"
              src="https://www.iavicenne.fr/static/img/molecule.png"
              alt="iAvicenne Logo"
              width={50}
              height={50}
              style={{ margin: "0", display: "block", verticalAlign: "middle" }}
            />
          </Grid.Col>
          <Grid.Col span={10}>
            <Title
              order={2}
              ta="left"
              style={{ marginTop: "10", marginBottom: "10" }}
            >
              {selectedMedicamentDetail?.dci}
              <Text ta="left" size="xs" fs="italic">
                {selectedMedicamentDetail?.nom_commercial} (
                {selectedMedicamentDetail?.commercialisation})
              </Text>
            </Title>
          </Grid.Col>
        </Grid>
        <Group>
          <Text ta="center" size="xs" fs="italic">
          </Text>
        </Group>
      </Card>
  
      <Card radius="12px" shadow="lg" padding="lg" withBorder={true}>
        <Stack>
          <Fieldset
            legend={
              <Text fw="bold" size="19px">
                Posologie
              </Text>
            }
            radius="12px"
            pd="xs"
            ta="left"
          >
            <Stack>
              <Textarea
                value={localMedicament?.commentaire || ""}
                onChange={(event) =>
                  updateMedicament("commentaire", event.currentTarget.value)
                }
                placeholder={"Click sur l'indication ou Prescription Manuelle"}
                variant="filled"
                size="sm"
                radius="12px"
                type="text"
                minRows={3}
                shadow="md"
              />
  
              <Collapse in={opened}>
                <Group grow>
                  {renderNumberInput("matin", "Matin")}
                  {renderNumberInput("midi", "Midi")}
                  {renderNumberInput("soir", "Soir")}
                  {renderNumberInput("coucher", "Coucher")}
                </Group>
                <Group>
                  {renderSwitch("nsMTE", "MTE")}
                  {renderSwitch("nsEFG", "EFG")}
                  {renderSwitch("nsCIF", "CIF")}
                </Group>
              </Collapse>
  
              <Group justify="flex-start">
                {renderSwitch("tttAld", "ALD")}
                {renderNumberInput("nombre_periode", "Pendant")}
                {renderSegmentedControl("periode", "Période", [
                  { value: "Jour", label: "Jour" },
                  { value: "Semaine", label: "Semaine" },
                  { value: "Mois", label: "Mois" },
                ])}
                {renderNumberInput("renouveler", "Renouveler")}
              </Group>
            </Stack>
          </Fieldset>
          <Group justify="flex-end" style={{ marginTop: "0.9rem" }}>
            <Button
              variant="light"
              size="xs"
              color="#94ADA7"
              radius="12px"
              onClick={toggle}
            >
              plus d'options
            </Button>
            <Button
              variant="light"
              size="xs"
              color="#94ADA7"
              radius="12px"
              onClick={() => handleTttFondChange(selectedMedicamentDetail)}
            >
              traitement de fond
            </Button>
            <Button
              variant="light"
              color="red"
              size="xs"
              radius="12px"
              onClick={() => handleReset()}
            >
              Réinitialiser
            </Button>
          </Group>
        </Stack>
      </Card>
  
      {selectedMedicamentDetail?.rcp && Object.keys(selectedMedicamentDetail.rcp).length > 0 && (
        <Card radius="12px" shadow="lg" padding="lg" withBorder={true}>
          <SegmentedControl
            className="RCPControl"
            withItemsBorders={false}
            orientation="horizontal"
            data={data}
            value={active}
            onChange={handleDocumentsControlChange}
            color="#94ADA7"
            radius="12px"
            size="xs"
            transitionDuration={500}
            transitionTimingFunction="linear"
            fullWidth
          />
          <ScrollArea
            offsetScrollbars
            scrollHideDelay={100}
            h={"40vh"}
          >
            <Tabs
              value={active}
              onChange={setActive}
              radius="12px"
              transitionDuration={800}
            >
              <Tabs.Panel value="indications" ta="start">
                {selectedMedicamentDetail?.rcp?.Indications?.length > 0 && (
                  <div>
                    {selectedMedicamentDetail.rcp.Indications.map(
                      (indication, index) => (
                        <Stack gap="sm" style={{ marginTop: "1rem" }} key={index}>
                          <Fieldset
                            className="posologieFieldset"
                            legend={
                              <Text fw="bold" size="17px">
                                {indication?.Description}
                              </Text>
                            }
                            radius="12px"
                            pd="xs"
                            ta="left"
                            onClick={() => updateMedicament("commentaire", indication?.Posologie)}
                          >
                            <Text fs="italic" c="dimmed" size="sm">
                              {indication?.Contexte}
                            </Text>
                            <Text size="sm">{indication?.Posologie}</Text>
                          </Fieldset>
                        </Stack>
                      )
                    )}
                  </div>
                )}
              </Tabs.Panel>
              <Tabs.Panel value="contre indications" ta="start">
                {selectedMedicamentDetail?.rcp?.["Contre-indications"]?.length > 0 && (
                  <div>
                    <ul>
                      {selectedMedicamentDetail.rcp["Contre-indications"].map(
                        (item, index) => (
                          <li key={index}>{item}</li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </Tabs.Panel>
              <Tabs.Panel value="effets indésirables" ta="start">
                {selectedMedicamentDetail?.rcp?.["Effets-indesirables"] && (
                  <div>
                    {Object.entries(selectedMedicamentDetail.rcp["Effets-indesirables"][0]).map(
                      ([category, effects]) => (
                        <div key={category} style={{ marginBottom: "1em" }}>
                          <h3>{category.replace(/_/g, " ")}</h3>
                          {Array.isArray(effects) ? (
                            <ul>
                              {effects.map((effect, i) => (
                                <li key={i}>{effect}</li>
                              ))}
                            </ul>
                          ) : (
                            <p>{effects}</p>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </Tabs.Panel>
              <Tabs.Panel value="précautions" ta="start">
                {selectedMedicamentDetail?.rcp?.Precautions?.length > 0 && (
                  <div>
                    <ul>
                      {selectedMedicamentDetail.rcp.Precautions.map(
                        (precaution, index) => (
                          <li key={index}>
                            {precaution || "Information non spécifiée"}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              </Tabs.Panel>
            </Tabs>
          </ScrollArea>
  
          {selectedMedicamentDetail?.rcp?.References?.length > 0 && (
            <div>
              {selectedMedicamentDetail.rcp.References.map(
                (reference, index) => (
                  <Group
                    justify="flex-end"
                    key={index}
                    gap="lg"
                    style={{ margin: "1em" }}
                  >
                    <strong>Date :</strong>{" "}
                    {reference?.Date || "Date non spécifiée"}{" "}
                    <strong>Source :</strong> {reference?.Source}
                  </Group>
                )
              )}
            </div>
          )}
        </Card>
      )}
    </Stack>
  );
}
