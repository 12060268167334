import React, { useState } from 'react';
import { Popover, Button, TextInput, Title, Group, Image, Text, Tooltip } from "@mantine/core";
import { IconPlus, IconAlertTriangleFilled, IconCopyPlusFilled, IconPillFilled, IconFileArrowRight } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { useStore } from "../Store";
import { client } from "../api/axiosClient";

export const SaveOrdonnanceType = () => {
  const { currentUser, ordonnanceData} = useStore();
  const [loading, setLoading] = useState(false);
  const [indication, setIndication] = useState('');

  const handleSave = async () => {
    if (!indication) return;
   
    setLoading(true);
    
    const ordonnanceTypeData = {
      practitioner: currentUser.id,
      indication: indication,
      MedicationRequest: ordonnanceData,
    };

    try {
      const response = await client.post('api/ordonnance-type/', ordonnanceTypeData);
      if (response.status === 201) {
        notifications.show({
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <Image
                fit="contain"
                src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                alt="iAvicenne Logo"
                width={25}
                height={25}
                style={{ margin: "0", display: "block" }}
              />
              <Text fw={700}>Ordonnance type enregistrée</Text>
            </Group>
          ),
          opacity: 0.7,
          autoClose: true,
          withCloseButton: true,
        });
       
      } else {
        notifications.show({
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <IconAlertTriangleFilled color="red" size="25"/>
              <Text fw={700}>Erreur</Text>
              <Text size="sm">Lors de l'enregistrement de l'ordonnance type</Text>
    
            </Group>
          ),
          opacity: 0.7,
          autoClose: true,
          withCloseButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover width={250} trapFocus position="left"  shadow="md" radius="12px" >
      <Popover.Target>
        <Button className="printButton"
          variant="outline"
          size="xs"
          color="#94ADA7"
          radius="12px"
          style={{ boxShadow: "0 2px 5px #bebebe" }}>
     
          <IconCopyPlusFilled size={16} />
          <IconPillFilled size={12} />

          </Button>
      </Popover.Target>
      
      <Popover.Dropdown >
        <Title size="h4" style={{marginBottom:10}}>Ordonnance Type</Title>
        <TextInput
          placeholder="Indication / Titre"
          variant="filled"
          radius="12px"
          size="xs"
          value={indication}
          onChange={(event) => setIndication(event.currentTarget.value)}
        />
        
        <Button 
          className='renouvelerButton'
          color="#94ADA7"
          radius="12px"
          size="xs"
          onClick={handleSave}
          loading={loading}
          disabled={loading || !indication}
          style={{ marginTop: '10px' }}
        >
          Sauvegarder
        </Button>
      </Popover.Dropdown>
    </Popover>
  );
};

export const RenewOrdonnanceType = ({ ordoTypeId }) => {
  const currentUser = useStore((state) => state.currentUser);
  const selectedPatientInfo = useStore((state) => state.selectedPatientInfo);
  const currentSessionInfo = useStore((state) => state.currentSessionInfo);
  const ordonnanceData = useStore((state) => state.ordonnanceData);
  const setOrdonnanceData = useStore((state) => state.setOrdonnanceData);
  
  const { setActive, setActiveTab } = useStore();
  const [loading, setLoading] = useState(false);

  const addMedicament = async (value) => {
    setLoading(true);
    const medicamentData = {
      practitioner: currentUser.id,
      subject: selectedPatientInfo.id,
      encounter: currentSessionInfo.encounter,
      medoc_id: value.medoc_id, 
      nom_commercial: value.nom_commercial,
      dci: value.dci,
      dosage: value.dosage,
      forme: value.forme,
      voie: value.voie,
      commercialisation: value.commercialisation,
      ciscode: value.ciscode,
      matin: value.matin,
      midi: value.midi,
      soir: value.soir,
      coucher: value.coucher,
      periode: value.periode,
      nombre_periode: value.nombre_periode,
      commentaire:value.commentaire,
      tttAld: value.tttAld,
      tttFond: value.tttFond,
      nsCIF: value.nsCIF,
      nsEFG: value.nsEFG,
      nsMTE: value.nsMTE,
      rcp: value.rcp,
    };

    try {
      if (Array.isArray(ordonnanceData)) {
        const existingIndex = ordonnanceData.findIndex(
          (item) => item.medoc_id === value.medoc_id
        );

        if (existingIndex !== -1) {
            const notificationId = notifications.show({
            withBorder: true,
            loading: false,
            radius: "lg",
            color: "#94ADA7",
            title: (
              <Group gap="xs">
                {/* <Image
                  fit="contain"
                  src="https://www.iavicenne.fr/static/img/brain_ai_3.png"
                  alt="iAvicenne Logo"
                  width={15}
                  height={15}
                  style={{ margin: "0", display: "block" }}
                /> */}
                <Text fw={700} size="xs">{value.nom_commercial} déjà Prescrit </Text>
              </Group>
            ),
            opacity: 0.7,
            autoClose: 1000,
            withCloseButton: true,
          });
        } else {
          const response = await client.post(
            "api/medication-requests/",
            medicamentData,
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          if (response.status === 201) {
            // Mettre à jour l'état local avec l'ID retourné par l'API
            ordonnanceData.push({ ...medicamentData, id: response.data.id });
            setOrdonnanceData(ordonnanceData);
            console.log("Ordonnance mise à jour");
    
           
        }
      
      };
    }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const renouvelerOrdo = async () => {
    setLoading(true);
    try {
      const response = await client.get(`api/ordonnance-type/${ordoTypeId}/`);

      if (response.status === 200) {
        const ordonnance = response.data.MedicationRequest;
        for (const medicament of ordonnance || []) {
            // console.log(medicament)
          await addMedicament(medicament);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip
    label={
    
        <Text size="xs">
         Renouveler l'ordonnance type
        </Text>
      
    }
    color="#94ADA7"
    withArrow
    multiline
  
    position="top"
    radius="12px"
    arrowOffset={25}
    arrowSize={8}
    transitionProps={{ transition: "pop", duration: 500 }}
    openDelay={1000}
  >
    <Button 
    className='renouvelerButton'
      color="#94ADA7"
      radius="12px"
      size="xs"
      onClick={() => {
        renouvelerOrdo(ordoTypeId);
        setActive("medicaments")
        setActiveTab("medicaments")
      }} 
      loading={loading} 
      disabled={loading}
    >
       <IconPlus size={16}/>
       <IconPillFilled size={18}/>
    
    </Button>
    </Tooltip>
   
 
  );
};

export const ShowOrdonnanceType = () => {
  const {setOrdonnancetypeData,
        setMiddleActiveTab,
        setMiddleActive, 
       
        } = useStore();

  const [loading, setLoading] = useState(false);
  const handleFetchOrdonnances = async () => {
    setLoading(true);
    try {
      const response = await client.get('api/ordonnance-type/');
      if (response.status === 200) {
        setLoading(false);
        setOrdonnancetypeData(response.data.results);
        console.log(response.data)
        setMiddleActiveTab('Ordonnance Type')
        setMiddleActive('Ordonnance Type')
      } else {
        setLoading(false);
        notifications.show({
          withBorder: true,
          loading: false,
          radius: "lg",
          color: "#94ADA7",
          title: (
            <Group gap="xs">
              <IconAlertTriangleFilled color="red" size="25"/>
              <Text fw={700}>Erreur</Text>
              <Text size="sm">lors de la récupération de la liste des Ordonnances types</Text>
            </Group>
          ),
          opacity: 0.7,
          autoClose: true,
          withCloseButton: true,
        });
      }
    } catch (error) {
      notifications.show({
        withBorder: true,
        loading: false,
        radius: "lg",
        color: "#94ADA7",
        title: (
          <Group gap="xs">
            <IconAlertTriangleFilled color="red" size="25"/>
            <Text fw={700}>Erreur</Text>
            <Text size="sm">{error.message || "Une erreur est survenue"}</Text>
          </Group>
        ),
        opacity: 0.7,
        autoClose: true,
        withCloseButton: true,
      });
    }
  };

  return (
    <Button
      className="printButton"
      variant="outline"
      size="xs"
      color="#94ADA7"
      radius="12px"
      style={{ boxShadow: "0 2px 5px #bebebe" }}
      onClick={handleFetchOrdonnances}
      loading={loading} 
      disabled={loading}
    > <IconFileArrowRight  size={16} />
      <IconPillFilled size={12} />
    </Button>
  );
};



